import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';

const CustomModal = ({
  title = '',
  text = '',
  visible,
  setVisible,
  onSubmit = () => {},
  showFooter = true,
  children,
  backdrop = 'static',
  alignment = '',
}) => {
  const handleSubmit = () => {
    setVisible(false);
    onSubmit();
  };
  return (
    <>
      <CModal
        alignment={alignment}
        backdrop={backdrop}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      >
        <CModalHeader>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {text}
          {children}
        </CModalBody>
        {showFooter && (
          <CModalFooter>
            <CButton color='secondary' onClick={() => setVisible(false)}>
              Хаах
            </CButton>
            <CButton color='primary' onClick={() => handleSubmit()}>
              Тийм
            </CButton>
          </CModalFooter>
        )}
      </CModal>
    </>
  );
};

export default CustomModal;
