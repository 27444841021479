import { cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CBadge,
  CButton,
  CCloseButton,
  CFormInput,
  CInputGroup,
  CListGroup,
  CListGroupItem,
  CRow,
} from '@coreui/react';
import React, { useRef, useState } from 'react';

export default function TreatmentList({
  isAdmin = true,
  handleGetTreatments,
  treatments = {},
  queryTreatments = [],
  onClick = () => {},
  onTreatmentRemove = () => {},
}) {
  const searchRef = useRef();
  const [text, setText] = useState('');
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Whatever you want to trigger
      handleGetTreatments(e.target.value);
    }
  };
  return (
    <div>
      {Object.keys(treatments).length > 0 ? (
        Object.keys(treatments).map((key) => {
          const data = treatments[key];
          return (
            <CListGroupItem className='d-flex justify-content-between align-items-center' component='button'>
              <div>
                {data.name}
                <CBadge color='primary' shape='rounded-pill'>
                  {data.price}₮
                </CBadge>
              </div>
              {isAdmin && (
                <div>
                  <CCloseButton
                    onClick={() => {
                      onTreatmentRemove(data);
                    }}
                  />
                </div>
              )}
            </CListGroupItem>
          );
        })
      ) : (
        <div className='alert alert-warning' role='alert'>
          Эмчилгээ хийгдээгүй байна
        </div>
      )}

      {isAdmin && (
        <CInputGroup className='mt-2'>
          <CFormInput
            onKeyDown={handleKeyPress}
            placeholder='Эмчилгээ'
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <CButton variant='outline' ref={searchRef} onClick={() => handleGetTreatments(text)}>
            <CIcon icon={cilSearch} />
          </CButton>
        </CInputGroup>
      )}
      {/* Query treatments */}
      <CListGroup className='mt-2'>
        {queryTreatments.map((obj) => {
          return (
            <CListGroupItem
              className='d-flex justify-content-between align-items-center'
              component='button'
              onClick={() => onClick(obj)}
            >
              {obj.name}
              <CBadge color='primary' shape='rounded-pill'>
                {obj.price}₮
              </CBadge>
            </CListGroupItem>
          );
        })}
      </CListGroup>
    </div>
  );
}
