import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CarouselItem from '../components/home/CarouselItem';
import Contact from '../pages/Contact';
import Doctors from '../pages/Doctors';
import { useAuth } from '../context/AuthContext';
import fbAuthService from '../services/firebase/firebaseAuthService';

function Home(props) {
  const { user } = useAuth();
  const [constants, setConstants] = useState({
    greeting: { title: '', text: '' },
    aboutUs: { title: '', text: '' },
    doctors: { title: '', text: '', data: [] },
    testimonials: { title: '', data: [] },
    googleMap: { coordinates: [], defaultCenter: {}, zoom: 15 },
  });
  useEffect(() => {
    fbAuthService
      .getLandingPageConstants()
      .then((res) => {
        console.log('res', res);
        setConstants(res);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const carouselItems = [
    {
      title: constants.greeting.title,
      paragraph: constants.greeting.text,
      // route: `/dashboard/me/reservation/create`,
      // buttonTxt: 'Захиалах',
    },

    {
      title: 'Цаг захиалга',
      paragraph: `Та доорхи товч дээр дарж цаг захиалах боломжтой.`,
      route: `/dashboard/me/reservation/create`,
      buttonTxt: 'Захиалах',
    },
  ];

  return (
    <div>
      {/* <div className='hero_area'> */}
      <div className='hero_area' style={{ position: 'initial' }}>
        <div className='hero_bg_box'>
          <img src='../../assets/images/hero-bg.png' alt='' />
        </div>

        {/* <!-- slider section --> */}
        <section className='slider_section'>
          <div id='customCarousel1' className='carousel slide' data-ride='carousel'>
            <div className='carousel-inner'>
              {carouselItems.map((obj, i) => {
                return <CarouselItem key={'carousal' + i} active={i === 0} {...obj} />;
              })}
            </div>
            <ol className='carousel-indicators'>
              {carouselItems.map((obj, i) => {
                return (
                  <li
                    key={'list' + i}
                    data-target='#customCarousel1'
                    data-slide-to={i}
                    className={i === 0 ? 'active' : ''}
                  ></li>
                );
              })}
              {/* <li data-target='#customCarousel1' data-slide-to='0' className='active'></li>
              <li data-target='#customCarousel1' data-slide-to='1'></li>
              <li data-target='#customCarousel1' data-slide-to='2'></li> */}
            </ol>
          </div>
        </section>
        {/* <!-- end slider section --> */}
      </div>

      {/* <!-- about section --> */}

      <section className='about_section layout_margin-bottom'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='img-box'>
                <img src='../../assets/images/about-img.jpg' alt='' />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='detail-box'>
                <div className='heading_container'>
                  <h2>
                    {/* About <span>Us</span> */}
                    {constants.aboutUs.title}
                  </h2>
                </div>
                <p>{constants.aboutUs.text}</p>
                {/* <a href='#/'> Read More </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- end about section --> */}

      

      {/* <!-- contact section --> */}
      <Contact {...constants.googleMap} />

      {/* <!-- end contact section --> */}

      
    </div>
  );
}

export default Home;