import { CButton, CCardBody, CCardFooter, CCol, CForm, CFormInput, CFormLabel, CInputGroup, CRow } from '@coreui/react';
import React from 'react';
export default function UserForm({
  user,
  setUser,
  emailSearch = true,
  phoneSearch = true,
  handleSubmit,
  validated,
  handleFindUserBy = () => {},
}) {
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser(name, value);
  };

  return (
    <div>
      <CForm noValidate validated={validated} onSubmit={handleSubmit}>
        <CCardBody>
          <CFormInput required type='hidden' name='uid' value={user.uid} onChange={handleChange} />

          <CRow className='mb-3'>
            <CFormLabel htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Цахим шуудан
            </CFormLabel>
            <CCol sm={8}>
              <CInputGroup className='mb-3'>
                <CFormInput
                  required
                  type='email'
                  placeholder='you@mail.com'
                  name='email'
                  value={user.email}
                  onChange={handleChange}
                />
                {emailSearch && (
                  <CButton
                    type='button'
                    color='primary'
                    variant='outline'
                    onClick={() => handleFindUserBy('email', user.email)}
                  >
                    Хайх
                  </CButton>
                )}
              </CInputGroup>
            </CCol>
          </CRow>
          <CRow className='mb-3'>
            <CFormLabel htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Утас
            </CFormLabel>
            <CCol sm={8}>
              <CInputGroup className='mb-3'>
                <CFormInput
                  required
                  type='tel'
                  pattern='[0-9]{8}|\+976[0-9]{8}'
                  placeholder='99001122'
                  name='phone'
                  value={user.phone}
                  onChange={handleChange}
                />
                {phoneSearch && (
                  <CButton
                    type='button'
                    color='primary'
                    variant='outline'
                    onClick={() => handleFindUserBy('phone', user.phone)}
                  >
                    Хайх
                  </CButton>
                )}
              </CInputGroup>
            </CCol>
          </CRow>
          <CRow className='mb-3'>
            <CFormLabel htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Овог
            </CFormLabel>
            <CCol sm={8}>
              <CFormInput
                required
                disabled
                type='text'
                placeholder='Овог'
                name='fname'
                value={user.fname}
                onChange={handleChange}
              />
            </CCol>
          </CRow>
          <CRow className='mb-3'>
            <CFormLabel htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Нэр
            </CFormLabel>
            <CCol sm={8}>
              <CFormInput
                required
                disabled
                type='text'
                placeholder='Нэр'
                name='lname'
                value={user.lname}
                onChange={handleChange}
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div className='d-grid gap-2 col-4 mx-auto'>
            <CButton color='info' size='lg' variant='outline' type='submit'>
              Захиалга үүсгэх
            </CButton>
          </div>
        </CCardFooter>
      </CForm>
    </div>
  );
}
