import React, { createContext, useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import fbAuthService from '../services/firebase/firebaseAuthService';
import localStorageService from '../services/localStorageService';

const UserContext = createContext({
  user: {
    uid: '',
    email: '',
    displayName: '',
    phone: '',
    photoURL: '',
    isAdmin: false,
    gender: '',
    createdAt: '',
    emailVerified: '',
  },

  getCurrentUserData: () => {},
  handleLogin: () => {},
  handleSignup: () => {},
  handleLogout: () => {},
  handleErr: (code) => {},
  handleToast: (text, status) => {},
  setShowSpinner: (state = false) => {},
});
export const useAuth = () => useContext(UserContext);

let userUnsub = null;
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [spinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // checkJwtAuth();
    // checkFirebaseAuth();
    // Fetch current user data

    getCurrentUserData();

    // User subscription
    const sub = handleAuthState();

    return () => {
      sub && sub();
    };
  }, []);

  const getCurrentUserData = async () => {
    const user = await fbAuthService.getCurrentUserData();
    setUser(user);
    return user;
  };
  const handleToast = (text, status) => {
    const toasts = {
      success: () => toast.success(text),
      error: () => toast.error(text),
      default: () => toast.success(text),
    };
    (toasts[status] || toasts['default'])();
  };
  const handleLogin = ({ email, pwd, rememberMe }) => {
    setShowSpinner(true);

    return fbAuthService
      .signInWithEmailAndPassword(email, pwd)
      .then((res) => {
        handleToast(`Тавтай морил ${res.user.email}.`);
        localStorageService.setItem('loginEmail', rememberMe ? email : '');
        // res.user.sendEmailVerification()
      })
      .catch((err) => {
        console.log('ERR', err);
        handleErr(err.code);
      })
      .finally(() => setShowSpinner(false));
  };
  const handleSignup = ({ email, pwd, rememberMe }) => {
    setShowSpinner(true);
    return fbAuthService
      .signUpWithEmailAndPassword(email, pwd)
      .then((res) => {
        handleToast('Амжилттай бүртгэгдлээ');
        localStorageService.setItem('loginEmail', rememberMe ? email : '');
      })
      .catch((err) => {
        console.log('ERR', err);
        handleErr(err.code);
      })
      .finally(() => setShowSpinner(false));
  };
  const handleErr = (code) => {
    const errors = {
      'auth/user-not-found': 'Хэрэглэгч олдсонгүй',
      default: 'Алдаа гарлаа',
      'auth/wrong-password': 'Нууц үг буруу байна',
      'auth/email-already-in-use': 'Цахим шууданг ашиглан хэрэглэгч үүссэн байна.',
    };
    console.log("errors[code] || errors['default']", errors[code] || errors['default']);
    handleToast(errors[code] || errors['default'], 'error');
  };
  const handleLogout = () => {
    setShowSpinner(true);
    return fbAuthService
      .signOut()
      .catch((err) => {
        console.log('ERR', err);
      })
      .finally(() => setShowSpinner(false));
  };
  const handleAuthState = () => {
    return fbAuthService.auth.onAuthStateChanged((user) => {
      console.log('onAuthStateChanged user.emailVerified', user?.emailVerified);
      if (user) {
        if (!userUnsub)
          userUnsub = fbAuthService.firestore.doc(`/users/${user.uid}`).onSnapshot(async (doc) => {
            let userData = doc.data();

            const result = await user.getIdTokenResult();
            const { isAdmin } = result.claims;
            // console.log('get id token USER', { ...userData, uid: doc.id, emailVerified, isAdmin });
            setUser({ ...userData, uid: doc.id, emailVerified: user.emailVerified, isAdmin });

            setShowSpinner(false);
            navigate({
              pathname: isAdmin ? '/dashboard/employees/list' : '/dashboard/me/reservation/create',
            });
          });
      } else {
        // User is signed out
        // ...
        //  Хэрэглэгч нэвтрээгүй үед
        userUnsub && userUnsub();
        userUnsub = null;
        setUser(null);
      }
    });
  };

  let value = {
    user,
    handleLogin,
    handleLogout,
    handleSignup,
    handleToast,
    setShowSpinner,
    getCurrentUserData,
    handleErr,
  };
  // console.log('User', user);
  return (
    <UserContext.Provider value={value}>
      <div>
        <Toaster />
        {spinner && <Spinner loading={spinner} />}
        {/* {true && <Spinner loading={true} />} */}
      </div>
      {children}
    </UserContext.Provider>
  );
};
