import React, { useState } from 'react';
import { BeatLoader, PropagateLoader } from 'react-spinners';
import { css } from '@emotion/react';

// Can be a string as well. Need to ensure each key-value pair ends with ;
import './style.css';
const override = css`
  position: absolute;
  left: 50%;
  top: 45%;
  margin-left: -4em;
  z-index: 2000;
`;
function Spinner({ loading = false, color = '#0984e3' }) {
  return (
    <div className='spinner-container'>
      <PropagateLoader color={color} loading={loading} css={override} size={20} />
    </div>
  );
}

export default Spinner;
