import {
  cilDrop,
  cilSpeedometer,
  cilUserPlus,
  cilList,
  cilAvTimer,
  cilListNumbered,
  cilUser,
  cilUserX,
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CNavItem, CNavTitle } from '@coreui/react';
import React from 'react';

export const adminNavigation = [
  // {
  //   component: CNavItem,
  //   name: 'Dashboard',
  //   to: '/dashboard',
  //   icon: <CIcon icon={cilSpeedometer} customClassName='nav-icon' />,
  //   badge: {
  //     color: 'info',
  //     //   text: 'NEW',
  //   },
  // },

  {
    component: CNavTitle,
    name: 'Цаг захиалга',
  },
  {
    component: CNavItem,
    name: 'Цаг жагсаалт',
    to: '/dashboard/reservation/list',
    icon: <CIcon icon={cilListNumbered} customClassName='nav-icon' />,
  },
  {
    component: CNavItem,
    name: 'Цаг авах',
    to: '/dashboard/reservation/create',
    icon: <CIcon icon={cilAvTimer} customClassName='nav-icon' />,
  },
  {
    component: CNavItem,
    name: 'Үйлчилгээ',
    to: '/dashboard/reservation/treatment',
    icon: <CIcon icon={cilList} customClassName='nav-icon' />,
  },

  {
    component: CNavTitle,
    name: 'Хэрэглэгч',
  },
  {
    component: CNavItem,
    name: 'Миний мэдээлэл',
    to: '/dashboard/me/info',
    icon: <CIcon icon={cilUser} customClassName='nav-icon' />,
  },
  {
    component: CNavItem,
    name: 'Жагсаалт',
    to: '/dashboard/reservation/users',
    icon: <CIcon icon={cilUser} customClassName='nav-icon' />,
  },
  {
    component: CNavItem,
    name: 'Давтан үзлэг дөхсөн',
    to: '/dashboard/reservation/users-filtered',
    icon: <CIcon icon={cilUser} customClassName='nav-icon' />,
  },

  {
    component: CNavTitle,
    name: 'Ажилчид',
  },
  {
    component: CNavItem,
    name: 'Жагсаалт',
    to: '/dashboard/employees/list',
    icon: <CIcon icon={cilList} customClassName='nav-icon' />,
  },
  {
    component: CNavItem,
    name: 'Цагийн хувиар',
    to: '/dashboard/employees/timetable/create',
    icon: <CIcon icon={cilAvTimer} customClassName='nav-icon' />,
  },
];
export const userNavigation = [
  {
    component: CNavTitle,
    name: 'Цаг захиалга',
    emailVerified: true,
  },
  {
    component: CNavItem,
    name: 'Цаг авах',
    to: '/dashboard/me/reservation/create',
    icon: <CIcon icon={cilAvTimer} customClassName='nav-icon' />,
    emailVerified: true,
  },
  {
    component: CNavItem,
    name: 'Миний түүх',
    to: '/dashboard/me/reservation/list',
    icon: <CIcon icon={cilListNumbered} customClassName='nav-icon' />,
    emailVerified: true,
  },

  {
    component: CNavTitle,
    name: 'Хэрэглэгч',
  },

  {
    component: CNavItem,
    name: 'Миний мэдээлэл',
    to: '/dashboard/me/info',
    icon: <CIcon icon={cilUser} customClassName='nav-icon' />,
  },
];
export const positions = [{ label: 'Эмч', value: 'doctor' }];
export const timetable = [
  { label: '08:00 - 17:00', value: '09' },
  { label: '09:00 - 18:00', value: '09' },
];
export const RESERVE_STATUS = { reserved: 'reserved', cancelled: 'cancelled' };
export const COLORS = {
  primary1: '#74b9ff',
  primary2: '#0984e3',
  orange: '#f4c965',
};
export const treatmentTypes = { treatment: 'treatment', service: 'service' };
export const treatmentOptions = [
  { label: 'Эмчилгээ', value: treatmentTypes.treatment },
  { label: 'Үйлчилгээ', value: treatmentTypes.service },
];
