import { cibFacebook } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormCheck,
  CFormTextarea,
  CRow,
  CWidgetStatsD,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import UserInfo from '../../../../components/UserInfo';
import OralCavity from '../../../../components/OralCavity';
import { useAuth } from '../../../../context/AuthContext';
import service from '../../../../services/firebase/reservationService';
import userService from '../../../../services/firebase/userService';
import helperService from '../../../../services/helperService';
import UserReservations from '../../../../components/UserReservations.js';
import CustomModal from '../../../../components/modals/Modals';
import { treatmentTypes } from '../../../../constants';

const MODAL_MODES = { create: 'create', delete: 'delete', edit: 'edit' };
const MODAL = {
  mode: MODAL_MODES.create,
  visible: false,
  title: '',
  text: '',
  data: { id: '', position: '', mark: '' },
};
const MARKS = ['A', 'B', 'C', 'D', 'E'];
export default function UserDetail() {
  const { user } = useAuth();
  const uid = user.uid;
  const [searchParams] = useSearchParams();
  // Query params: doctorId, resId
  const resId = searchParams.get('resId'); // "testCode"

  const { setShowSpinner, handleToast } = useAuth();
  const [showModal, setShowModal] = useState(MODAL);
  // Active reservation id
  const [activeId, setActiveId] = useState();
  // const [user, setUser] = useState();
  const [reservations, setReservations] = useState([]);
  const [queryTreatments, setQueryTreatments] = useState([]);

  useEffect(() => {
    // GET user doc
    // userService
    //   .getUserSnap(uid)
    //   .then((snap) => {
    //     if (!snap.exists) throw new Error('Хэрэглэгч олдсонгүй');
    //     const data = { ...snap.data(), id: snap.id, createdAt: snap.data().createdAt.toDate() };

    //     setUser(data);
    //   })
    //   .catch((err) => {
    //     console.log('ERR', err);
    //     handleToast('Хэрэглэгч олдсонгүй', 'error');
    //   });
    // GET reservations
    const reserveSub = service.getReservationsByUser(uid).onSnapshot((snap) => {
      const data = snap.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });

      // Set active id as first reservation if resId is not exist
      if (!resId && data.length > 0) setActiveId(data[0].id);
      // Set active id as resId (query param) if resId is exist
      else if (resId && data.find((obj) => obj.id === resId)) {
        setActiveId(resId);
      }

      console.log('data', uid, data);
      setReservations(data);
    });
    return () => {
      reserveSub();
    };
  }, []);

  console.log('resId', resId);
  // TODO:
  // Select user in reservation
  // Create user without password

  const handleUserInfo = () => {
    const name = user?.displayName || '';
    const age = user?.birthday ? helperService.getAgeByDate(user.birthday) : '-';
    const gender = user?.gender ? helperService.getGenderLabel(user?.gender) : '-';
    const phone = user?.phone || '-';

    return [
      { label: 'Нэр', value: name },
      { label: 'Нас', value: age },
      { label: 'Хүйс', value: gender },
      { label: 'Утас', value: phone },
    ];
  };

  const handleSubmit = () => {
    switch (showModal.mode) {
      case MODAL_MODES.create:
        break;

      case MODAL_MODES.delete:
        break;
      case MODAL_MODES.edit:
        break;

      default:
        return;
    }
  };

  const reservation = reservations?.find((obj) => obj.id === activeId);

  return (
    <div>
      <CRow>
        <CCol md='6'>
          <UserInfo
            isAdmin={false}
            img={user?.photoURL}
            setReservations={setReservations}
            activeId={activeId}
            reservation={reservation}
            info={handleUserInfo()}
          />
          <CCard className='mb-4'>
            {/* <CCardHeader></CCardHeader> */}
            {/* === Teeth section ===  */}
            <CCardBody>
              <OralCavity
                activeId={activeId}
                reservations={reservations}
                onClick={(id, position) => {
                  console.log('onClick', id, position);
                  setShowModal((prev) => {
                    return {
                      ...prev,
                      title: 'Шүд тэмдэглэх',
                      visible: true,
                      data: { id, position },
                    };
                  });
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
        {/* === TABS AND CONTENT === */}
        <CCol md='6'>
          {/* reservations */}
          <UserReservations
            isAdmin={false}
            user={user}
            activeId={activeId}
            setActiveId={setActiveId}
            reservations={reservations}
            setReservations={setReservations}
            queryTreatments={queryTreatments}
          />
        </CCol>
      </CRow>
      <CustomModal
        alignment='center'
        backdrop={false}
        title={showModal.title}
        text={showModal.text}
        setVisible={(visible) => {
          setShowModal((prev) => {
            return { ...prev, visible };
          });
        }}
        visible={showModal.visible}
        onSubmit={handleSubmit}
        showFooter={false}
      >
        {(showModal.mode === MODAL_MODES.create || showModal.mode === MODAL_MODES.edit) && (
          <div>
            <p>{showModal.data?.teeth?.label}</p>
            {/* <CFormTextarea
              type='text'
              rows={5}
              disabled
              value={showModal.data?.mark}
              placeholder='Шүдний тэмдэглэл'
              onChange={(e) =>
                setShowModal((prev) => {
                  prev.data.mark = e.target.value;
                  return { ...prev };
                })
              }
            /> */}

            {MARKS.map((obj, i) => (
              <CFormCheck
                required
                disabled
                id={'MARKS' + i}
                type='radio'
                name='mark'
                value={showModal.data?.mark}
                label={'Тэмдэглэгээ'}
              />
            ))}
          </div>
        )}
      </CustomModal>
    </div>
  );
}
