import { cilArrowRight, cilClock, cilDelete, cilInfo, cilList, cilPeople, cilSearch, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CButtonGroup, CCardBody, CCol, CFormInput, CInputGroup, CModalFooter, CRow } from '@coreui/react';
import {
  CAvatar,
  CCard,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../../components/modals/Modals';
import { useAuth } from '../../../../context/AuthContext';

import service from '../../../../services/firebase/userService';
import CreateUser from './Create';
const MODAL_MODES = { create: 'create', delete: 'delete', edit: 'edit' };

const MODAL = {
  mode: MODAL_MODES.create,
  visible: false,
  title: '',
  text: '',
  itemId: '',
  itemName: '',
};
const USER_FORM = {
  email: '',
  password: '',
  phone: '',
  displayName: '',
  lname: '',
  fname: '',
  birthday: '',
  gender: '',
  note: '',
  // photoURL: '',
};
const includeColumns = ['birthday', 'displayName', 'email', 'fname', 'lname', 'gender', 'phone'];
// TODO: Find by phone, email
function Users(props) {
  const navigate = useNavigate();
  const { handleToast, setShowSpinner } = useAuth();

  const [showModal, setShowModal] = useState(MODAL);
  const [form, setForm] = useState(USER_FORM);
  const [validated, setValidated] = useState(false);

  const [query, setQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const sub = service.getUsers(setUsers);
    return () => {
      sub();
    };
  }, []);

  const handleTimetable = (uid) => {
    console.log('uid', uid);
    if (!uid) return;
    navigate({
      pathname: `${uid}`,
    });
  };
  const handleUserInfo = (uid) => {
    if (!uid) return;
    navigate({
      pathname: `/dashboard/employees/users/${uid}`,
    });
  };
  const handleCreate = () => {
    setForm({ ...USER_FORM });
    setShowModal({ mode: MODAL_MODES.create, visible: true, title: 'Эмчилгээ үүсгэх', text: '' });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const tmpForm = event.currentTarget;
    if (tmpForm.checkValidity() === true) {
      console.log('submit', form);

      setShowSpinner(true);
      try {
        delete form.photoURL;

        await service.createUser({ ...form, displayName: `${form.lname} ${form.fname}` });
        setShowModal({ ...showModal, visible: false });
        console.log('SUCCEED');
        handleToast('Хэрэглэгч амжилттай үүсгэлээ');
      } catch (err) {
        console.log('SUBMIT ERR', err);
        handleToast(err.message, 'error');
      } finally {
        setShowSpinner(false);
      }
    }
  };
  const handleDelete = (uid) => {
    setShowModal({
      mode: MODAL_MODES.delete,
      visible: true,
      title: 'Баталгаажуулах',
      text: 'Хэрэглэгч устгахдаа итгэлтэй байна уу',
      itemId: uid,
    });
  };
  const onDelete = async (uid) => {
    try {
      setShowSpinner(true);
      await service.removeUser(uid);

      handleToast('Хэрэглэгч амжилттай устгалаа');
    } catch (error) {
      console.log('ERROR', error);
      handleToast('Алдаа гарлаа', 'error');
    } finally {
      setShowSpinner(false);
      setShowModal(MODAL);
    }
  };
  const handleTyping = (e) => {
    const value = e.target.value;
    setQuery(value);
    let query = value.toLowerCase().trim();
    let filtered = [];
    if (query !== '') {
      filtered = users.filter((item) => {
        return Object.keys(item).some((key) =>
          includeColumns.includes(key) ? item[key].toString().toLowerCase().includes(query) : false
        );
      });
    }
    setFilteredUsers(filtered);
  };
  return (
    <>
      <CCard className='mb-4'>
        <CCardHeader>
          <CRow>
            <CCol sm={7}>
              <CButton type='submit' onClick={handleCreate}>
                Шинэ хэрэглэгч
              </CButton>
            </CCol>
            <CCol sm={5}>
              <CInputGroup>
                <CButton className='float-end' variant='outline'>
                  <CIcon icon={cilSearch} />
                </CButton>
                <CFormInput placeholder='Хайлт' value={query} onChange={handleTyping} />
              </CInputGroup>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>
          <CTable align='middle' className='mb-0 border' hover responsive>
            <CTableHead color='light'>
              <CTableRow>
                <CTableHeaderCell className='text-center'>
                  <CIcon icon={cilList} />
                </CTableHeaderCell>
                <CTableHeaderCell>Цахим шуудан</CTableHeaderCell>
                <CTableHeaderCell>Нэр</CTableHeaderCell>
                <CTableHeaderCell>Утас</CTableHeaderCell>
                <CTableHeaderCell>Үүсгэсэн огноо</CTableHeaderCell>
                <CTableHeaderCell>Үйлдэл</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {(query.length > 0 ? filteredUsers : users).map((item, index) => (
                <CTableRow v-for='item in tableItems' key={index}>
                  <CTableDataCell className='text-center'>
                    {/* {index + 1} */}
                    <CAvatar
                      size='md'
                      src={item.photoURL}
                      // status={item.status === RESERVE_STATUS.reserved ? 'success' : 'error'}
                    />
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{`${item.email}`}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{`${item.displayName}`}</div>
                  </CTableDataCell>

                  <CTableDataCell>
                    <div className='clearfix'>
                      <div>{item.phone}</div>
                    </div>
                    {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                  </CTableDataCell>

                  <CTableDataCell>
                    <div>{moment(item.createdAt).fromNow()}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButtonGroup role='group' aria-label='Basic example'>
                      <CButton
                        color='success'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() => handleUserInfo(item.id)}
                      >
                        <CIcon icon={cilUser} size='xl' />
                      </CButton>
                      <CButton
                        color='info'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() => handleTimetable(item.id)}
                      >
                        <CIcon icon={cilArrowRight} size='xl' />
                      </CButton>{' '}
                      <CButton
                        color='danger'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() => handleDelete(item.id)}
                      >
                        <CIcon icon={cilDelete} size='xl' />
                      </CButton>
                    </CButtonGroup>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>

      <CustomModal
        title={showModal.title}
        text={showModal.text}
        setVisible={(visible) => {
          setValidated(false);
          setShowModal((prev) => {
            return { ...prev, visible };
          });
        }}
        visible={showModal.visible}
        onSubmit={handleSubmit}
        showFooter={false}
      >
        {(showModal.mode === MODAL_MODES.create || showModal.mode === MODAL_MODES.edit) && (
          <CreateUser form={form} setForm={setForm} handleSubmit={handleSubmit} validated={validated} />
        )}
        {showModal.mode === MODAL_MODES.delete && (
          <CModalFooter style={{ marginTop: 8 }}>
            <CButton color='secondary' onClick={() => setShowModal(MODAL)}>
              Хаах
            </CButton>
            <CButton color='primary' onClick={() => onDelete(showModal.itemId)}>
              Тийм
            </CButton>
          </CModalFooter>
        )}
      </CustomModal>
    </>
  );
}

export default Users;
