import { cilMinus, cilNoteAdd, cilPlus } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCallout,
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
  CFormTextarea,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TreatmentList from './TreatmentList';

export default function UserReservations({
  isAdmin = true,
  user = {},
  activeId = 0,
  setActiveId = () => {},
  setReservations = () => {},
  reservations = [],
  queryTreatments = [],
  handleGetTreatments = () => {},
  handleUpdate = () => {},
}) {
  const navigate = useNavigate();

  // console.log('reservations', JSON.stringify(reservations));
  const onDiagnosisChange = (resIdx, value) => {
    setReservations((prev) => {
      prev[resIdx].examination.diagnosis = value;
      console.log('prev[resIdx]', prev[resIdx]);
      return [...prev];
    });
  };
  const onTreatmentChange = (resIdx, { id, price, name }) => {
    setReservations((prev) => {
      prev[resIdx].examination.treatments[id] = { id, price, name };
      console.log('prev[resIdx]', id);
      return [...prev];
    });
  };
  const onTreatmentRemove = (resIdx, { id }) => {
    setReservations((prev) => {
      delete prev[resIdx].examination.treatments[id];
      console.log('prev[resIdx]', prev[resIdx]);
      return [...prev];
    });
  };
  const handleNavigateReservation = () => {
    if (!isAdmin) return;
    if (user.id)
      navigate({
        pathname: `/dashboard/reservation/create?uid=${user.id}`,
      });
  };

  return (
    <>
      <CCard className='mb-4'>
        <CCardHeader>
          {reservations.length === 0 && <h5>Үзлэг</h5>}
          {/* tab: reservation date */}

          <CNav style={{ overflowY: 'auto', flexWrap: 'nowrap' }} variant='pills' role='tablist'>
            {isAdmin && (
              <CButton variant='ghost' onClick={handleNavigateReservation}>
                <CIcon icon={cilPlus} size='lg' />
              </CButton>
            )}
            {reservations?.map(({ id, begin, date, status }, i) => {
              const tab = `${date} ${begin}`;
              return (
                <CNavItem key={id + 'tab'}>
                  <CNavLink href='#' active={activeId === id} onClick={() => setActiveId(id)}>
                    {tab}
                  </CNavLink>
                </CNavItem>
              );
            })}
          </CNav>
        </CCardHeader>
        <CCardBody>
          {reservations.length === 0 && <CCallout color='info'>Үзлэгийн түүх байхгүй байна</CCallout>}

          {/* Reservation content */}
          <CTabContent>
            {reservations?.map(({ id, begin, date, status, examination }, i) => {
              return (
                <CTabPane key={id + 'content'} role='tabpanel' aria-labelledby='home-tab' visible={activeId === id}>
                  <div className='d-flex'>
                    <div style={{ flex: 1 }}>
                      <CFormTextarea
                        rows='30'
                        disabled={!isAdmin}
                        placeholder='Онош/Тэмдэглэл..'
                        name='diagnosis'
                        value={examination?.diagnosis}
                        onChange={(e) => {
                          onDiagnosisChange(i, e.target.value);
                        }}
                      />
                    </div>
                    <div className='vr mx-3'></div>
                    <div style={{ flex: 1 }}>
                      <TreatmentList
                        isAdmin={isAdmin}
                        treatments={examination?.treatments}
                        handleGetTreatments={handleGetTreatments}
                        queryTreatments={queryTreatments}
                        onClick={(val) => onTreatmentChange(i, val)}
                        onTreatmentRemove={(val) => onTreatmentRemove(i, val)}
                      />
                    </div>
                  </div>
                  {isAdmin && (
                    <div className='d-grid gap-2 col-6 mx-auto'>
                      <CButton className='mt-2' size='lg' color='primary' onClick={() => handleUpdate()}>
                        Хадгалах
                      </CButton>
                    </div>
                  )}
                </CTabPane>
              );
            })}
          </CTabContent>
        </CCardBody>
      </CCard>
    </>
  );
}
