import React from 'react';

function Doctor({ name, position, img }) {
  return (
    <div className='box'>
      <div className='img-box'>
        {/* <img src='../../assets/images/d2.jpg' alt='' /> */}
        <img src={img} alt='Зураг' />
      </div>
      <div className='detail-box'>
        <div className='social_box'>
          <a href=''>
            <i className='fa fa-facebook' aria-hidden='true'></i>
          </a>
          <a href=''>
            <i className='fa fa-twitter' aria-hidden='true'></i>
          </a>
          <a href=''>
            <i className='fa fa-youtube' aria-hidden='true'></i>
          </a>
          <a href=''>
            <i className='fa fa-linkedin' aria-hidden='true'></i>
          </a>
        </div>
        <h5>{name}</h5>
        <h6 className=''>{position}</h6>
      </div>
    </div>
  );
}

export default Doctor;
