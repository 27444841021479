import moment from 'moment';

const helperService = {
  isSameDay(date1, date2, unit = 'day') {
    return moment(date1).isSame(moment(date2), unit);
  },
  isWithinDate(date, dates = []) {
    return dates.some((d) => d === moment(date).format('YYYY-MM-DD'));
  },

  isDatetimeBefore(dt = '2000-01-01 00:00') {
    return moment(new Date(), 'YYYY-MM-DD hh:mm').isBefore(moment(dt, 'YYYY-MM-DD hh:mm'));
  },
  getAgeByDate(date) {
    return moment().diff(date, 'years');
  },
  getGenderLabel(gender) {
    const labels = { male: 'Эр', female: 'Эм' };
    return labels[gender] || '';
  },
  timelineLabels(startTime, hours, interval) {
    const periodInMinutes = hours * 60; // timeDiff(startTime, endTime);

    console.log('periodInMinutes', startTime, periodInMinutes);
    const timeLabels = {};
    const startTimeMoment = moment(startTime, 'HH:mm');
    for (let i = 0; i <= periodInMinutes; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, 'm');

      timeLabels[startTimeMoment.format('HH:mm')] = '';
    }

    return timeLabels;
  },
};

export default helperService;
