import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CFormSelect,
  CListGroup,
  CListGroupItem,
} from '@coreui/react';
import { cilDelete, cilPlus, cilRecycle } from '@coreui/icons';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-calendar';
import CIcon from '@coreui/icons-react';
import resService from '../../../../../services/firebase/reservationService.js';

import './styles.css';
import { useAuth } from '../../../../../context/AuthContext';
import TileDescription from '../../../../../components/calendar/Description/index.js';
const ACTIONS = { create: 'create', remove: 'remove' };
const TILE_DESC = [
  { label: 'Өнөөдрийн огноо', type: 'now', text: '1', class: 'react-calendar__tile--now' },
  { label: 'Сонгох боломжгүй огноо', type: 'now', text: '1', class: 'disabledTileBg' },
  { label: 'Сонгогдсон огноо', type: 'now', text: '1', class: 'react-calendar__tile--active' },
  { label: 'Хувиар үүссэн огноо', type: 'now', text: '1', class: 'customCalendarTile' },
];
export default function CreateSchedule() {
  const { handleToast, setShowSpinner } = useAuth();
  // Form values
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [action, setAction] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState();

  // Option data
  const [schedules, setSchedule] = useState([]);
  const [scheduleOptions, setScheduleOptions] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    // Schedule options
    resService.getSchedules().then((data) => {
      if (data.length > 0) {
        setSelectedSchedule(data[0].id);
        setScheduleOptions(data);
      }
    });
    // Employee list
    resService.getEmployees().then((data) => {
      if (data.length > 0) setSelectedEmployee(data[0].id);
      setEmployees(data);
    });

    const subSchedule = resService.getActiveSchedule(setSchedule);

    return () => subSchedule();
  }, []);
  console.log('schedules', schedules);
  const tileClassName = ({ date, view }) => {
    // Add class to tiles in month view only
    if (view === 'month') {
      const dateFormatted = moment(date).format('YYYY-MM-DD');
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      const dateReservations = selectedDates.filter((obj) => obj === dateFormatted);
      if (dateReservations.length > 0) {
        return 'selectedTile';
      }
      const createdDate = schedules.find((obj) => obj.id === dateFormatted);
      if (createdDate) {
        return 'customCalendarTile';
      }
    }
  };

  //   const tileContent = ({ date, view }) => {
  //     // Add class to tiles in month view only
  //     if (view === 'month') {
  //       // Check if a date React-Calendar wants to check is on the list of dates to add class to
  //       // if (datesToAddContentTo.find((dDate) => isSameDay(dDate, date))) {
  //       // console.log('date, view', date, view);
  //       const dateReservations = reservations.filter((obj) => helperService.isSameDay(obj.date, date));
  //       if (dateReservations.length > 0) {
  //         return <TileContent data={dateReservations} />;
  //       }
  //     }
  //   };
  const handleDisabledTile = ({ date, view }) => {
    // Add class to tiles in month view only

    if (view === 'month') {
      const dateFormatted = moment(date).format('YYYY-MM-DD');
      let bool = moment().isAfter(date);
      // Check if a date React-Calendar wants to check is after today
      const currentSchedule = schedules.find((obj) => obj.id === dateFormatted);

      if (action === ACTIONS.create) {
        if (currentSchedule && currentSchedule?.employees) {
          // console.log(currentSchedule?.employees[selectedEmployee]);
          bool = bool || currentSchedule?.employees[selectedEmployee];
        }
      }
      if (action === ACTIONS.remove) {
        // Өнөөдрөөс өмнөх өдөр устгахгүй
        // Зөвхөн schedule.employees.id === true буюу үүссэн байгаа хувиар устгах боломжтой харагдана
        if (currentSchedule && currentSchedule?.employees) {
          // Хэрэглэгч үүссэнг тэмдэглэх талбар false буюу устсан төлөвтэй бол disable
          if (!currentSchedule?.employees[selectedEmployee]) bool = bool || true;
        } else {
          // Хэрэглэгч үүссэнг тэмдэглэх талбар үүсээгүй бол disable
          bool = bool || true;
        }
      }

      return bool;
    }
  };
  const handleClickDay = (dt) => {
    setSelectedDates((prev) => {
      const date = moment(dt).format('YYYY-MM-DD');

      if (prev.find((obj) => obj === date)) {
        prev = prev.filter((obj) => obj !== date);
      } else {
        prev = [...prev, date];
      }

      prev = prev.sort();
      console.log('date', prev);
      return [...prev];
    });
  };

  const handleScheduledTime = (currentSchedule) => {
    const timesArr = Object.keys(currentSchedule?.times || {})
      .map((key) => {
        return key;
      })
      .sort();
    return timesArr;
  };
  const currentSchedule = scheduleOptions?.find((obj) => obj.id === selectedSchedule);
  console.log('selectedEmployee', selectedEmployee, action, selectedDates, selectedSchedule);

  const handleSubmit = async () => {
    switch (action) {
      case ACTIONS.create:
        if (!selectedEmployee || !selectedDates || !selectedSchedule) return handleToast('Дата бүрэн биш', 'error');
        const params = { employeeId: selectedEmployee, dates: selectedDates, scheduleId: selectedSchedule };
        setShowSpinner(true);
        try {
          await resService.createEmployeeSchedule(params);
          console.log('SUCCEED');
          handleToast('Үйлдэл амжилттай');
          setSelectedDates([]);
        } catch (err) {
          console.log('SUBMIT ERR', err);
          handleToast(err.message, 'error');
        } finally {
          setShowSpinner(false);
        }

        break;
      case ACTIONS.remove:
        if (!selectedEmployee || !selectedDates) return handleToast('Дата бүрэн биш', 'error');
        const removeParams = { employeeId: selectedEmployee, dates: selectedDates };
        setShowSpinner(true);
        try {
          await resService.deleteEmployeeSchedule(removeParams);
          console.log('SUCCEED');
          setSelectedDates([]);
          handleToast('Үйлдэл амжилттай');
        } catch (err) {
          console.log('SUBMIT ERR', err);
          handleToast(err.message, 'error');
        } finally {
          setShowSpinner(false);
        }

        break;

      default:
        break;
    }
  };
  return (
    <>
      <CCard className='mb-4'>
        <CCardHeader>
          <h4>Цагийн хуваарь үүсгэх</h4>
        </CCardHeader>
        <CCardBody>
          {/* <div className='scheduleContainer'> */}
          <div className='row'>
            <section className='col-md-4'>
              <h5 className='text-center'>Ажилтан сонгох</h5>
              <CFormSelect
                required
                label='State'
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
              >
                {employees.map((obj) => (
                  <option key={obj.email} value={obj.id}>
                    {obj.displayName}
                  </option>
                ))}
              </CFormSelect>
            </section>{' '}
            {selectedEmployee && (
              <section className='col-md-4'>
                <h5 className='text-center'>Үйлдэл</h5>
                <CListGroup>
                  <CButton
                    color='info'
                    className='mb-1'
                    variant={action === ACTIONS.create ? 'outline' : 'ghost'}
                    onClick={() =>
                      setAction((prev) => {
                        setSelectedDates([]);
                        return ACTIONS.create;
                      })
                    }
                  >
                    <CIcon icon={cilPlus} /> Цагийн хуваарь үүсгэх
                  </CButton>
                  <CButton
                    color='info'
                    className='mb-1'
                    variant={action === ACTIONS.remove ? 'outline' : 'ghost'}
                    onClick={() =>
                      setAction((prev) => {
                        setSelectedDates([]);
                        return ACTIONS.remove;
                      })
                    }
                  >
                    <CIcon icon={cilRecycle} /> Цагийн хуваарь устгах
                  </CButton>
                </CListGroup>
              </section>
            )}
          </div>
        </CCardBody>
        {/* </div> */}
      </CCard>
      {action && (
        <CCard className='mb-4'>
          {/* <CCardHeader>
          <h4>Цагийн хуваарь үүсгэх</h4>
        </CCardHeader> */}
          <CCardBody>
            <div className='row my-3'>
              {/* {action && ( */}
              <section className='col-md-4'>
                <h5 className='text-center'>Өдөр сонгох</h5>
                <Calendar
                  className={['shadow p-3 mb-5 bg-white rounded', 'border-0', 'w-100']}
                  onChange={(value, event) => handleClickDay(value)}
                  //   value={selectedDate}
                  //   tileContent={tileContent}
                  tileClassName={tileClassName}
                  tileDisabled={handleDisabledTile}
                />{' '}
                <TileDescription data={TILE_DESC} />
              </section>
              {/* )} */}
              {selectedDates.length > 0 && (
                <>
                  <section className='col-md-4'>
                    <h5 className='text-center'>Сонгогдсон өдрүүд: {selectedDates.length}</h5>
                    <CListGroup className='dateContainer'>
                      {selectedDates.map((date) => (
                        // <CListGroupItem color={'info'} component='a' href='#'>
                        <CListGroupItem className='d-flex justify-content-between align-items-center'>
                          {date}
                          <CIcon
                            size='xl'
                            className='removeIcon'
                            icon={cilDelete}
                            style={{ color: 'red' }}
                            onClick={() => {
                              handleClickDay(date);
                            }}
                          />
                        </CListGroupItem>
                      ))}
                    </CListGroup>
                  </section>

                  {action === ACTIONS.create && (
                    <section className='col-md-4'>
                      <h5 className='text-center'>Ажлын цаг сонгох </h5>

                      <CFormSelect
                        required
                        label='State'
                        value={selectedSchedule}
                        onChange={(e) => setSelectedSchedule(e.target.value)}
                      >
                        {scheduleOptions.map((obj) => (
                          <option key={obj.label} value={obj.id}>
                            {obj.label}
                          </option>
                        ))}
                      </CFormSelect>
                      <CListGroup className='timeContainer'>
                        {handleScheduledTime(currentSchedule).map((key) => (
                          <CListGroupItem className='d-flex justify-content-between align-items-center'>
                            {key}
                          </CListGroupItem>
                        ))}
                      </CListGroup>
                    </section>
                  )}
                </>
              )}
            </div>
          </CCardBody>{' '}
          {selectedDates.length > 0 && (
            <CCardFooter>
              <div className='d-grid gap-2 col-4 mx-auto'>
                <CButton color='info' size='lg' variant='outline' onClick={handleSubmit}>
                  {action === ACTIONS.create ? 'Хуваарь үүсгэх' : 'Хуваарь устгах'}
                </CButton>
              </div>
            </CCardFooter>
          )}
          {/* </div> */}
        </CCard>
      )}
    </>
  );
}
