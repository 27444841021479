import { cilClock, cilDelete, cilPeople } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CButtonGroup, CCardBody, CCol, CModalFooter, CRow } from '@coreui/react';
import {
  CAvatar,
  CCard,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../components/modals/Modals';
import { positions } from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import fbAuthService from '../../../services/firebase/firebaseAuthService';
import EmployeeCreate from './Create';
const MODAL_MODES = { create: 'create', delete: 'delete', edit: 'edit' };

const MODAL = {
  mode: MODAL_MODES.create,
  visible: false,
  title: '',
  text: '',
  itemId: '',
  itemName: '',
  itemEmail: '',
};

const EMPLOYEE_FORM = {
  email: '',
  position: positions[0].value,
  lname: '',
  fname: '',
  password: '',
  passwordConfirm: '',
};
function EmployeeList(props) {
  const navigate = useNavigate();
  const { handleToast, setShowSpinner } = useAuth();

  const [employees, setEmployees] = useState([]);
  const [showModal, setShowModal] = useState(MODAL);
  const [validated, setValidated] = useState(false);

  const [form, setForm] = useState(EMPLOYEE_FORM);

  console.log('employees', employees);

  useEffect(() => {
    const unsub = fbAuthService.getEmployees().onSnapshot((snap) => {
      const employees = snap.docs.map((doc) => {
        return { ...doc.data(), createdAt: doc.data().createdAt.toDate(), uid: doc.id };
      });
      setEmployees(employees);
    });

    return () => {
      unsub();
    };
  }, []);
  const handleCreate = () => {
    setForm({ ...EMPLOYEE_FORM });
    setShowModal({ mode: MODAL_MODES.create, visible: true, title: 'Ажилтан үүсгэх', text: '' });
  };
  const handleDelete = (uid, email) => {
    setShowModal({
      mode: MODAL_MODES.delete,
      visible: true,
      title: 'Баталгаажуулах',
      text: 'Ажилтан устгахдаа итгэлтэй байна уу',
      itemId: uid,
      itemEmail: email,
    });
  };
  const onDelete = async (uid, email) => {
    try {
      setShowSpinner(true);
      await fbAuthService.removeEmployee({ uid, email });

      handleToast('Хэрэглэгч амжилттай устгалаа');
    } catch (error) {
      console.log('ERROR', error);
      handleToast('Алдаа гарлаа', 'error');
    } finally {
      setShowSpinner(false);
      setShowModal(MODAL);
    }
  };
  const handleTimetable = (uid) => {
    navigate({
      pathname: `/dashboard/employees/list/${uid}/timetable`,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    const tmpForm = e.currentTarget;
    if (tmpForm.checkValidity() === true) {
      // console.log('FORM', form);
      try {
        setShowSpinner(true);
        const { email, password, photoURL, position, fname, lname } = form;
        await fbAuthService.createEmployee({ email, password, displayName: `${lname} ${fname}`, photoURL, position });
        // setShowModal({ ...showModal, visible: false });
        setShowModal(MODAL);
        handleToast('Хэрэглэгч амжилттай үүслээ');
      } catch (error) {
        console.log('ERROR', error);
        handleToast('Алдаа гарлаа', 'error');
      } finally {
        setShowSpinner(false);
      }
    }
  };
  return (
    <>
      <CCard className='mb-4'>
        <CCardHeader>
          <CButton type='submit' onClick={handleCreate}>
            Шинэ ажилтан
          </CButton>
        </CCardHeader>

        <CCardBody>
          <CTable align='middle' className='mb-0 border' hover responsive>
            <CTableHead color='light'>
              <CTableRow>
                <CTableHeaderCell className='text-center'>
                  <CIcon icon={cilPeople} />
                </CTableHeaderCell>
                <CTableHeaderCell>Цахим шуудан</CTableHeaderCell>
                <CTableHeaderCell>Нэр</CTableHeaderCell>
                <CTableHeaderCell>Ажлын байр</CTableHeaderCell>
                <CTableHeaderCell>Статус</CTableHeaderCell>
                <CTableHeaderCell>Үүсгэсэн огноо</CTableHeaderCell>
                <CTableHeaderCell>Үйлдэл</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {employees.map((item, index) => (
                <CTableRow v-for='item in tableItems' key={index}>
                  <CTableDataCell className='text-center'>
                    <CAvatar size='md' src={item.photoURL} status={item.status ? 'success' : 'error'} />
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{item.email}</div>
                    {/* <div className='small text-medium-emphasis'>
                    <span> New </span>| Registered: {item.createdAt}
                  </div> */}
                  </CTableDataCell>

                  <CTableDataCell>
                    <div className='clearfix'>
                      <div className='float-start'>{item.displayName}</div>
                      {/* <div className='float-end'>
                      <small className='text-medium-emphasis'>{item.position}</small>
                    </div> */}
                    </div>
                    {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{item.position}</div>
                    {/* <div className='small text-medium-emphasis'>
                    <span> New </span>| Registered: {item.createdAt}
                  </div> */}
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{item.status ? 'Идэвхитэй' : 'Идэвхигүй'}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{moment(item.createdAt).fromNow()}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButtonGroup role='group' aria-label='Basic example'>
                      <CButton
                        color='success'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() => handleTimetable(item.uid)}
                      >
                        <CIcon icon={cilClock} size='xl' />
                      </CButton>{' '}
                      <CButton
                        color='danger'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() => handleDelete(item.uid, item.email)}
                      >
                        <CIcon icon={cilDelete} size='xl' />
                      </CButton>
                      {/* <CButton color='secondary' variant='ghost' shape='rounded-pill'>
                        Secondary
                      </CButton>
                      <CButton color='success' variant='ghost' shape='rounded-pill'>
                        Success
                      </CButton> */}
                    </CButtonGroup>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
      <CustomModal
        title={showModal.title}
        text={showModal.text}
        setVisible={(visible) => {
          setValidated(false);
          setShowModal((prev) => {
            return { ...prev, visible };
          });
        }}
        visible={showModal.visible}
        showFooter={false}
      >
        {(showModal.mode === MODAL_MODES.create || showModal.mode === MODAL_MODES.edit) && (
          <EmployeeCreate form={form} setForm={setForm} handleSubmit={handleSubmit} validated={validated} />
        )}
        {showModal.mode === MODAL_MODES.delete && (
          <CModalFooter style={{ marginTop: 8 }}>
            <CButton color='secondary' onClick={() => setShowModal(MODAL)}>
              Хаах
            </CButton>
            <CButton color='primary' onClick={() => onDelete(showModal.itemId, showModal.itemEmail)}>
              Тийм
            </CButton>
          </CModalFooter>
        )}
      </CustomModal>
    </>
  );
}

export default EmployeeList;
