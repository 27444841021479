import React from 'react';
import { useAuth } from '../../context/AuthContext';

export default function UserInfo({
  img = '',

  setReservations = () => {},
  activeId = '',
  info = [],
  reservations = [],
  isAdmin = true,
}) {
  // const { handleToast } = useAuth();

  const handleUpdateNextDate = (e) => {
    if (!activeId) return;
    // if (reservations.length === 0) return handleToast('Үйлдэл амжилтгүй', 'error');

    setReservations((prev) => {
      // const index = prev.findIndex((obj) => obj.id === activeId);
      // prev[index].nextDate = e.target.value;
      console.log('set reservation');
      prev[0].nextDate = e.target.value;
      return [...prev];
    });
  };
  const nextDate = reservations.length > 0 ? reservations[0]?.nextDate : '';

  return (
    <div className='card mb-4' style={{ '--cui-card-cap-bg': 'white' }}>
      <div className='card-header position-relative d-flex justify-content-center align-items-center'>
        <div className='avatar avatar-lg' style={{ width: '6rem', height: '8rem' }}>
          <img src={img} className='avatar-img' alt='profile' />
        </div>
      </div>
      <div className='card-body row text-center'>
        {info.map((obj, i) => {
          return (
            <>
              {i !== 0 && <div className='vr'></div>}
              <div className='col'>
                <div className='fs-5 fw-semibold'>{obj.label}</div>
                <div className='text-uppercase text-medium-emphasis small'>{obj.value}</div>
              </div>
            </>
          );
        })}

        {/* <div className='vr'></div> */}
        <div className='col'>
          <div className='fs-5 fw-semibold'>Давтан үзлэг</div>
          {/* <div className='text-uppercase text-medium-emphasis small'>{nextDate}</div> */}
          {nextDate && (
            <input
              style={{ 'text-align': 'right' }}
              className='border-0 text-right text-uppercase text-medium-emphasis small'
              type='date'
              value={nextDate}
              disabled={!isAdmin}
              onChange={handleUpdateNextDate}
            />
          )}
        </div>
        {/* <div className='vr'></div>
        <div className='col'>
          <div className='fs-5 fw-semibold'>Хүйс</div>
          <div className='text-uppercase text-medium-emphasis small'>{'Эр'}</div>
        </div> */}
      </div>
    </div>
  );
}
