import { CButton } from '@coreui/react';
import React from 'react';

export default function ActiveButton({ name, isActive = false, onClick, className = '' }) {
  return (
    <div className={`treatmentBtn ${isActive ? 'active' : ''} ${className}`} onClick={onClick}>
      <span>{name}</span>
    </div>
  );
}
