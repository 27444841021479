import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCol, CRow } from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-calendar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActiveButton from '../../../../components/reservation/ActiveButton/index.js';
import FormTime from '../../../../components/reservation/FormTime.js';
import UserForm from '../../../../components/reservation/FormUser.js';
import { treatmentOptions, treatmentTypes } from '../../../../constants.js';
import { useAuth } from '../../../../context/AuthContext.js';
import resService from '../../../../services/firebase/reservationService.js';
import userService from '../../../../services/firebase/userService';
import helperService from '../../../../services/helperService';

const FORM = {
  service: '',
  date: '',
  begin: '',
  user: {},
  employee: {},
};
function CreateReservation(props) {
  const { handleToast, setShowSpinner } = useAuth();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  // Query params: doctorId, resId
  const uid = searchParams.get('uid'); // "testCode"

  const [form, setForm] = useState(FORM);

  const [validated, setValidated] = useState(false);
  const [reservationServices, setReservationServices] = useState([]);
  const [schedule, setSchedule] = useState([]);
  // const [showCalendar, setShowCalendar] = useState(false);
  // Сонгосон өдрүүдийн эмч нарийн үзлэгийн цагнууд
  const [dailyDrsSchedules, setDailyDrsSchedules] = useState({});

  useEffect(() => {
    // Үйлчилгээ
    const sub = resService.getTreatmentsSnap(setReservationServices, treatmentTypes.service);
    const subSchedule = resService.getActiveSchedule(setSchedule);
    return () => {
      sub();
      subSchedule();
    };
  }, []);
  useEffect(() => {
    if (uid) {
      setShowSpinner(true);
      // GET user doc
      userService
        .getUserSnap(uid)
        .then((snap) => {
          if (!snap.exists) throw new Error('Хэрэглэгч олдсонгүй');
          const { email, lname, fname, phone, photoURL } = snap.data();
          const user = { id: snap.id, email, lname, fname, phone, photoURL };
          setForm({ ...form, user: user });
        })
        .catch((err) => {
          console.log('ERR', err.message);
          handleToast(err.message, 'error');
        })
        .finally(() => setShowSpinner(false));
    }
  }, [uid]);

  const handleDisabledTile = ({ date, view }) => {
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is within any of the ranges
      return !helperService.isWithinDate(
        date,
        schedule.map((obj) => obj.id)
      );
    }
  };
  const handleMinMax = () => {
    if (schedule.length === 0) return { max: new Date(), min: new Date() };

    const min = schedule[0],
      max = schedule[schedule.length - 1];

    return { max: moment(max.id).toDate(), min: moment(min.id).toDate() };
  };
  const handleClickDay = (dt, event) => {
    const date = moment(dt).format('YYYY-MM-DD');
    console.log('date', date);

    setForm((prev) => {
      // Update date and clear begin time
      return { ...prev, date, begin: '' };
    });
    if (!dailyDrsSchedules[date]) {
      // Fetch reservation schedule if not exists
      setShowSpinner(true);
      resService
        .getReservationSchedule(date)
        .then((snap) => {
          console.log('SNAP', snap.size);
          return snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
        })
        .then((data) => {
          console.log('DATA', data);
          setDailyDrsSchedules((prev) => {
            return { ...prev, [date]: data };
          });
        })
        .catch((err) => {
          console.log('ERR', err.message);
          handleToast(err.message, 'error');
        })
        .finally(() => setShowSpinner(false));
    }
  };
  const handleFindUserBy = async (type, value) => {
    try {
      let users;

      setShowSpinner(true);
      switch (type) {
        case 'phone':
          users = await userService.getUserBy('phone', value);
          break;
        case 'email':
        default:
          users = await userService.getUserBy('email', value);
      }
      console.log('users', users);
      if (!users || users.length === 0) return handleToast('Хэрэглэгч олдсонгүй', 'error');

      const { id, email, lname, fname, phone, photoURL } = users[0];
      const user = { id, email, lname, fname, phone, photoURL };
      setForm({ ...form, user: user });
    } catch (err) {
      console.log('ERR', err.message);
      handleToast(err.message, 'error');
    } finally {
      setShowSpinner(false);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const tmpForm = event.currentTarget;
    if (tmpForm.checkValidity() === true) {
      console.log('FORM', form);
      if (!form?.user?.id) return handleToast('Хэрэглэгчийн мэдээлэл олдсонгүй', 'error');

      setShowSpinner(true);
      try {
        await resService.createReservation(form);
        console.log('SUCCEED');
        handleToast('Цаг захиалга амжилттай');
        navigate({
          pathname: '/dashboard/reservation/list',
        });
      } catch (err) {
        console.log('SUBMIT ERR', err);
        handleToast(err.message, 'error');
      } finally {
        setShowSpinner(false);
      }
    }
  };

  return (
    <div>
      <CCard className='mb-4'>
        <CCardHeader>
          <CRow>
            <CCol sm={5}>
              <h5 id='traffic' className='card-title mb-0'>
                Үйлчилгээний төрөл сонгоно уу.
              </h5>
            </CCol>
            <CCol sm={7} className='d-none d-md-block'>
              <strong className='float-end'>Заавал сонгох</strong>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>
          <CRow>
            {reservationServices.map((service, i) => {
              const { id, name, price } = service;
              return (
                <CCol className='mb-2' md={6} key={service.id}>
                  <ActiveButton
                    {...service}
                    isActive={form.service.id === service.id}
                    onClick={() => setForm({ ...form, service: { id, name, price } })}
                  />
                </CCol>
              );
            })}
          </CRow>
        </CCardBody>
        {/* <CCardFooter>
          <div className='d-grid gap-2 col-4 mx-auto'>
            <CButton color='info' size='lg' variant='outline' onClick={() => setShowCalendar((prev) => !prev)}>
              Захиалгийн өдөр сонгох
            </CButton>
          </div>
        </CCardFooter> */}
      </CCard>

      {/* CALENDAR */}
      {form.service && (
        <CCard className='mb-4'>
          <CCardHeader>
            <CRow>
              <CCol sm={5}>
                <h5 id='traffic' className='card-title mb-0'>
                  Цаг захиалах өдөр цаг
                </h5>
              </CCol>
              <CCol sm={7} className='d-none d-md-block'>
                <strong className='float-end'>Заавал сонгох</strong>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              {/* Үзүүлэх өдөр сонголт */}
              <Calendar
                className={['shadow p-3 mb-5 bg-white rounded', 'border-0']}
                onClickDay={handleClickDay}
                value={form.date ? moment(form.date).toDate() : ''}
                tileDisabled={handleDisabledTile}
                formatDay={(locale, date) => moment(date).format('M/D')}
                maxDate={handleMinMax().max}
                minDate={handleMinMax().min}
                maxDetail={'month'}
                // showNavigation={false}
                showNeighboringMonth={false}
              />
            </CRow>
          </CCardBody>
          <CCardFooter>
            {/* Эмч болон, цаг сонголт */}
            <FormTime
              selectedDate={form.date}
              dailyDrSchedules={dailyDrsSchedules[form.date]}
              beginTime={form.begin}
              employee={form.employee}
              onClick={(employee, begin) =>
                setForm({
                  ...form,
                  employee,
                  begin,
                })
              }
            />
          </CCardFooter>
        </CCard>
      )}

      {/* Үйлчлүүлэгчийн мэдээлэл */}
      {form.begin && (
        <CCard className='mb-4'>
          <CCardHeader>
            <CRow>
              <CCol sm={5}>
                <h5 id='traffic' className='card-title mb-0'>
                  Хэрэглэгчийн мэдээлэл
                </h5>
              </CCol>
            </CRow>
          </CCardHeader>

          <UserForm
            handleSubmit={handleSubmit}
            handleFindUserBy={handleFindUserBy}
            validated={validated}
            user={form.user}
            setUser={(name, val) =>
              setForm((prev) => {
                prev.user[name] = val;
                return { ...prev };
              })
            }
          />
        </CCard>
      )}
    </div>
  );
}

export default CreateReservation;
