import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import moment from 'moment';
import { RESERVE_STATUS } from '../../constants';

// import 'firebase/auth';
import fbService, { fb } from './firebaseAuthService';

class UserService {
  firestore;
  function;
  constructor() {
    // UNCOMMENT IF YOU WANT TO USE FIREBASE

    this.firestore = fbService.firestore;
    this.function = fbService.function;
  }

  getUsers = (setUsers = () => {}) => {
    return this.firestore.collection('users').onSnapshot((snap) => {
      const data = snap.docs.map((doc) => {
        return { ...doc.data(), id: doc.id, createdAt: doc.data().createdAt.toDate() };
      });
      // console.log('Data', data);
      setUsers(data);
    });
  };

  getUserSnap = (id) => {
    return this.firestore.collection('users').doc(id).get();
  };
  getUserBy = (type = '', value = '') => {
    const ref = this.firestore.collection('users');

    if (type !== 'email' && type !== 'phone') throw new Error('Хайлтын төрөл олдсонгүй');
    if (type === 'phone') {
      // Make sure country code is not repeated
      value = value.replaceAll('+976', '');
      value = `+976${value}`;
    }

    return ref
      .where(type, '==', value)
      .get()
      .then((snap) => {
        return snap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
      });
  };
  createUser = (data) => {
    const create = this.function.httpsCallable('userFn-create');
    return create(data);
  };
  createUserSelf = (data) => {
    const create = this.function.httpsCallable('userFn-createSelf');
    return create(data);
  };
  sendLink = ({ uid, email, type }) => {
    if (!uid || !email || !type) throw new Error('Параметр дутуу');
    const sendLink = this.function.httpsCallable('userFn-sendLink');
    return sendLink({ uid, email, type });
  };
  updateUser = (data) => {
    // Make sure country code is not repeated
    data.phone = data.phone.replaceAll('+976', '');
    data.phone = `+976${data.phone}`;
    const update = this.function.httpsCallable('userFn-update');
    return update(data);
  };
  removeUser = (uid) => {
    // Make sure country code is not repeated
    const remove = this.function.httpsCallable('userFn-remove');
    return remove({ uid });
  };
}
const instance = new UserService();

export default instance;
