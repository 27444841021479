import React from 'react';
import { MARK_TYPES } from '../teeth';
import styles from './toothStyle.module.css';

export default function Tooth({
  id = '',
  marks = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' },
  type = MARK_TYPES[5],
  lineNum = 0,
  markedTooth = {},
  onClick = () => {},
}) {
  const isEven = lineNum % 2 === 0;

  return (
    <div>
      {isEven && (
        <div>
          <div>{id}</div>
          <div onClick={() => onClick(0)} class={styles.rowMain} style={{ marginBottom: 5 }}>
            {marks[0] || ''}
          </div>
        </div>
      )}

      <div class={styles.container}>
        <div onClick={() => onClick(1)} class={styles.row}>
          {marks[1]}
        </div>
        <div class={styles.rowMid}>
          <div onClick={() => onClick(2)}>{marks[2]}</div>
          <div onClick={() => onClick(3)}>{marks[3]}</div>
          {type === MARK_TYPES[5] && <div onClick={() => onClick(4)}>{marks[4]}</div>}
        </div>
        <div onClick={() => onClick(5)} class={styles.row}>
          {' '}
          {marks[5] || ''}{' '}
        </div>
      </div>
      {!isEven && (
        <div>
          <div onClick={() => onClick(0)} class={styles.rowMain} style={{ marginTop: 5 }}>
            {marks[0] || ''}
          </div>{' '}
          <div>{id}</div>
        </div>
      )}
    </div>
  );
}
