import { cilArrowRight, cilClock, cilDelete, cilList, cilPeople, cilX } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CButtonGroup, CCardBody } from '@coreui/react';
import {
  CAvatar,
  CCard,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from '../../../../services/firebase/reservationService';
import { RESERVE_STATUS } from '../../../../constants';
import { useAuth } from '../../../../context/AuthContext';
import CustomModal from '../../../../components/modals/Modals';
import helperService from '../../../../services/helperService';
function ListReservation(props) {
  const navigate = useNavigate();
  const { setShowSpinner, handleToast } = useAuth();
  const [reservations, setReservations] = useState([]);
  const [showModal, setShowModal] = useState({ visible: false, data: {} });

  useEffect(() => {
    //   TODO: FILTER AND CRUD reservation

    const unsub = service.getReservations().onSnapshot((snap) => {
      const reservations = snap.docs.map((doc) => {
        return { ...doc.data(), createdAt: doc.data()?.createdAt?.toDate(), id: doc.id };
      });
      console.log('      reservations', reservations);
      setReservations(reservations);
    });
    return () => {
      unsub();
    };
  }, []);
  const handleDetail = (resId, uid) => {
    if (!uid) return;
    navigate({
      pathname: `/dashboard/reservation/users/${uid}?resId=${resId}`,
    });
  };
  const handleCancel = (id) => {
    console.log('ITEM ID ', id);
    if (!id) return;
    setShowSpinner(true);

    service
      .cancelReservation(id)
      .then((res) => {
        handleToast('Үйлдэл амжилттай');
      })
      .catch((err) => {
        handleToast('Алдаа: ' + err.message, 'error');
        setShowModal({ visible: false, data: {} });
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };
  const handleNavigateReservation = () => {
    navigate({
      pathname: `/dashboard/reservation/create`,
    });
  };
  const handleIsCancellable = ({ status, date, begin }) => {
    console.log('status, date, begin', status, date, begin);
    if (status === RESERVE_STATUS.cancelled) return false;
    if (!helperService.isDatetimeBefore(`${date} ${begin}`)) return false;
    return true;
  };
  return (
    <>
      <CCard className='mb-4'>
        <CCardHeader>
          <CButton type='submit' onClick={handleNavigateReservation}>
            Цаг авах
          </CButton>
        </CCardHeader>

        <CCardBody>
          <CTable align='middle' className='mb-0 border' hover responsive>
            <CTableHead color='light'>
              <CTableRow>
                <CTableHeaderCell className='text-center'>
                  <CIcon icon={cilList} />
                </CTableHeaderCell>
                <CTableHeaderCell>Огноо</CTableHeaderCell>
                <CTableHeaderCell>Эмчилгээ</CTableHeaderCell>
                <CTableHeaderCell>Эмч</CTableHeaderCell>
                <CTableHeaderCell>Үйлчлүүлэгч</CTableHeaderCell>
                <CTableHeaderCell>Утас</CTableHeaderCell>
                <CTableHeaderCell>Статус</CTableHeaderCell>
                <CTableHeaderCell>Үүсгэсэн огноо</CTableHeaderCell>
                <CTableHeaderCell>Үйлдэл</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {reservations.map((item, index) => (
                <CTableRow v-for='item in tableItems' key={index}>
                  <CTableDataCell className='text-center'>
                    {index + 1}
                    {/* <CAvatar
                      size='md'
                      src={item.photoURL}
                      status={item.status === RESERVE_STATUS.reserved ? 'success' : 'error'}
                    /> */}
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{`${item.date} ${item.begin}`}</div>
                  </CTableDataCell>

                  <CTableDataCell>
                    <div className='clearfix'>
                      <div>{item?.service?.name}</div>
                      <div className='small text-medium-emphasis float-center'>
                        <span> {item?.service?.price}₮ </span>
                      </div>
                    </div>
                    {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{item.employee.displayName}</div>
                    {/* <div className='small text-medium-emphasis'>
                    <span> New </span>| Registered: {item.createdAt}
                  </div> */}
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{`${item.user.fname} ${item.user.lname}`}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{item.user.phone}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      color={item.status === RESERVE_STATUS.reserved ? 'success' : 'danger'}
                      variant='outline'
                      shape='rounded-pill'
                    >
                      {item.status === RESERVE_STATUS.reserved ? 'Захиалсан' : 'Цуцалсан'}
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{moment(item.createdAt).fromNow()}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButtonGroup role='group' aria-label='Basic example'>
                      <CButton
                        color='success'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() => handleDetail(item.id, item?.user?.id)}
                      >
                        <CIcon icon={cilArrowRight} size='xl' />
                      </CButton>
                      {handleIsCancellable(item) && (
                        <CButton
                          color={'danger'}
                          variant='ghost'
                          shape='rounded-pill'
                          onClick={() => setShowModal({ data: item, visible: true })}
                        >
                          <CIcon icon={cilDelete} size='xl' style={{ color: 'red' }} />
                        </CButton>
                      )}
                    </CButtonGroup>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
      <CustomModal
        title={'Та итгэлтэй байна уу?'}
        text={`Та "${showModal.data?.date} ${showModal.data?.begin}" цаг захиалгийг цуцлахдаа итгэлтэй байна уу?`}
        setVisible={(visible) => setShowModal({ ...showModal, visible })}
        visible={showModal.visible}
        onSubmit={() => handleCancel(showModal.data.id)}
      ></CustomModal>
    </>
  );
}

export default ListReservation;
