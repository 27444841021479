import {
  CAlert,
  CAlertLink,
  CButton,
  CCallout,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { fb } from '../../../../services/firebase/firebaseAuthService';
import service from '../../../../services/firebase/userService';

const GENDER = [
  { label: 'Эр', value: 'male' },
  { label: 'Эм', value: 'female' },
];
const LINK_TYPES = { password: 'password', emailVerification: 'emailVerification' };

export default function MyInfo() {
  const { handleToast, setShowSpinner, user, getCurrentUserData } = useAuth();
  const [form, setForm] = useState({ ...user });
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    handleEmailVerification(true);
  }, []);

  useEffect(() => {
    if (user) {
      setForm({ ...user });
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const tmpForm = event.currentTarget;
    if (tmpForm.checkValidity() === true) {
      console.log('submit', form);

      setShowSpinner(true);
      try {
        delete form.photoURL;

        await service.updateUser({ ...form, displayName: `${form.lname} ${form.fname}` });

        console.log('SUCCEED');
        handleToast('Хэрэглэгч амжилттай шинэчлэлээ');
        setValidated(false);
      } catch (err) {
        console.log('SUBMIT ERR', err);
        handleToast(err.message, 'error');
      } finally {
        setShowSpinner(false);
      }
    }
  };
  const handleEmailVerification = async (checkVerified = false) => {
    if (checkVerified && user.emailVerified) {
      getCurrentUserData();
    } else {
      const user = await getCurrentUserData();

      console.log('handleEmailVerification', user, fb.auth().currentUser.emailVerified);
      if (user.emailVerified) {
        handleToast('Имэйл баталгаажсан байна');
      } else {
        console.log('email not verified');
        handleToast('Имэйл баталгаажаагүй байна.', 'error');
      }
    }
  };
  const handleReset = async () => {
    try {
      setShowSpinner(true);
      await service.sendLink({ uid: user.uid, email: user.email, type: LINK_TYPES.emailVerification });
      handleToast('Нууц үг сэргээх линк илгээсэн');
    } catch (err) {
      console.log('SUBMIT ERR', err);
      handleToast(err.message, 'error');
    } finally {
      setShowSpinner(false);
    }
  };
  return (
    <div>
      <CForm noValidate validated={validated} onSubmit={handleSubmit}>
        <CRow className='mb-3'>
          {!user?.isAdmin && !user.emailVerified && (
            <CAlert color='warning'>
              <svg className='flex-shrink-0 me-2' width='24' height='24' viewBox='0 0 512 512'>
                <rect
                  width='32'
                  height='176'
                  x='240'
                  y='176'
                  fill='var(--ci-primary-color, currentColor)'
                  className='ci-primary'
                ></rect>
                <rect
                  width='32'
                  height='32'
                  x='240'
                  y='384'
                  fill='var(--ci-primary-color, currentColor)'
                  className='ci-primary'
                ></rect>
                <path
                  fill='var(--ci-primary-color, currentColor)'
                  d='M274.014,16H237.986L16,445.174V496H496V445.174ZM464,464H48V452.959L256,50.826,464,452.959Z'
                  className='ci-primary'
                ></path>
              </svg>
              Та имэйлээ баталгаажсаны дараа цаг авах боломжтой. Та цахим шуудангаа шалгаж баталгаажуулах линк дээр
              дарсны дараа{' '}
              <CAlertLink href='#' onClick={() => handleEmailVerification()}>
                энд дарна уу
              </CAlertLink>
              .
            </CAlert>
          )}
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Цахим шуудан
          </CFormLabel>

          <CCol sm={10}>
            <CInputGroup>
              <CFormInput
                required
                disabled
                placeholder='you@mail.com'
                type='email'
                value={form.email}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, email: e.target.value };
                  })
                }
              />
              <CButton
                type='button'
                color={user.emailVerified ? 'success' : 'danger'}
                variant='outline'
                id='inputGroupFileAddon04'
                onClick={handleEmailVerification}
              >
                {user.emailVerified ? 'Баталгаажсан' : 'Баталгаажаагүй'}
              </CButton>{' '}
              <CButton type='button' color={'info'} variant='outline' id='inputGroupFileAddon04' onClick={handleReset}>
                Баталгаажуулах линк илгээх
              </CButton>
            </CInputGroup>
          </CCol>
        </CRow>

        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Утас
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='tel'
              pattern='[0-9]{8}|\+976[0-9]{8}'
              placeholder='99001122'
              value={form.phone}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, phone: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Овог
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='text'
              placeholder='Овог'
              value={form.lname}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, lname: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Нэр
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='text'
              placeholder='Нэр'
              value={form.fname}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, fname: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Хүйс
          </CFormLabel>
          <CCol sm={10}>
            {GENDER.map((obj, i) => (
              <CFormCheck
                key={'gender' + i}
                required
                inline
                id={'genderCheck' + i}
                type='radio'
                name='gender'
                value={obj.value}
                label={obj.label}
                defaultChecked={obj.value == form?.gender}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, gender: e.target.value };
                  })
                }
              />
            ))}
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Төрсөн огноо
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='date'
              value={form.birthday}
              pattern='\d{4}-\d{2}-\d{2}'
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, birthday: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Нэмэлт мэдээлэл
          </CFormLabel>
          <CCol sm={10}>
            <CFormTextarea
              type='text'
              value={form.note}
              placeholder='...'
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, note: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        {/* <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Зураг
          </CFormLabel>
          <CCol sm={10}>
          
            <CFormInput
              required
              type='file'
              value={form.photoURL}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, photoURL: e.target.value };
                })
              }
            />
          </CCol>
        </CRow> */}
        <CButton color='primary' type='submit'>
          Хадгалах
        </CButton>
      </CForm>
    </div>
  );
}
