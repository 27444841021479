import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import moment from 'moment';
import { RESERVE_STATUS } from '../../constants';
import helperService from '../helperService';

// import 'firebase/auth';
import fbService, { fb } from './firebaseAuthService';

class ReservationService {
  firestore;
  function;
  constructor() {
    // UNCOMMENT IF YOU WANT TO USE FIREBASE

    this.firestore = fbService.firestore;
    this.function = fbService.function;
  }

  getReservationByDoctor = (uid) => {
    //   generally it's better to use uid for docId
    return this.firestore
      .collection('reservation')
      .where('employee.id', '==', uid)
      .where('status', '==', RESERVE_STATUS.reserved);
  };
  getEmployees = () => {
    //   generally it's better to use uid for docId
    return this.firestore
      .collection('employee')
      .where('status', '==', true)
      .get()
      .then((snap) => {
        return snap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
      });
  };
  createTreatment = (data) => {
    //   generally it's better to use uid for docId
    return this.firestore.collection('treatments').add({
      ...data,
      name: data.name.toUpperCase(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      createdByName: firebase.auth().currentUser.displayName,
    });
  };

  editTreatment = (id, { name, price, status, type }) => {
    //   generally it's better to use uid for docId
    return this.firestore.collection('treatments').doc(id).update({ name, price, status, type });
  };
  deleteTreatment = (id) => {
    //   generally it's better to use uid for docId
    return this.firestore.collection('treatments').doc(id).delete();
  };
  getTreatmentsSnap = (setData = () => {}, type) => {
    //   generally it's better to use uid for docId
    let ref = this.firestore.collection('treatments');
    if (type) ref = ref.where('type.value', '==', type);

    return ref.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), createdAt: doc.data().createdAt?.toDate(), id: doc.id };
      });

      setData(data);
    });
  };
  getTreatments = (type, queryTxt = '') => {
    //   generally it's better to use uid for docId
    let ref = this.firestore.collection('treatments').where('type.value', '==', type);
    let query = queryTxt.toUpperCase();
    if (queryTxt) {
      ref = ref.where('name', '>=', query).where('name', '<=', query + '\uf8ff');
    }
    return ref.get().then((snap) => {
      return snap.docs.map((doc) => {
        return { ...doc.data(), createdAt: doc.data().createdAt?.toDate(), id: doc.id };
      });
    });
  };
  getActiveSchedule = (setData = () => {}) => {
    //   generally it's better to use uid for docId
    return this.firestore
      .collection('schedule')
      .where(firebase.firestore.FieldPath.documentId(), '>=', moment().format('YYYY-MM-DD'))
      .limit(60)
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), createdAt: doc.data().createdAt?.toDate(), id: doc.id };
        });
        // console.log('Data', data);
        setData(data);
      });
  };
  getReservationSchedule = (dt) => {
    //   generally it's better to use uid for docId
    // TODO:
    // Times => Object {"09:00":{user: {id:"",displayName:''} }}
    // employee => { name :"bayasgalan", uid:''}
    return this.firestore.collection('schedule').doc(dt).collection('reservation').get();
  };
  getScheduleByEmployee = (dt, employeeId) => {
    return this.firestore.collection('schedule').doc(dt).collection('reservation').doc(employeeId).get();
  };

  addSchedule = (label = '09:00 - 18:00', startTime = '09:00', workingHour = 9, interval = 30) => {
    const timeline = helperService.timelineLabels(startTime, workingHour, interval);
    console.log('timeline', timeline);
    return this.firestore.collection('options').add({ label, type: 'schedule', times: timeline });
  };
  getSchedules = () => {
    return this.firestore
      .collection('options')
      .where('type', '==', 'schedule')
      .get()
      .then((snap) => {
        return snap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
      });
  };
  getReservations = (dt) => {
    // TODO: FILTER
    //   generally it's better to use uid for docId

    return this.firestore.collection('reservation').orderBy('date', 'desc').orderBy('begin', 'desc');
  };
  getReservationsByNextDate = ({ begin, end }) => {
    console.log('begin, end', begin, end);
    //   generally it's better to use uid for docId
    return this.firestore
      .collection('reservation')
      .where('status', '==', RESERVE_STATUS.reserved)
      .where('nextDate', '>=', begin)
      .where('nextDate', '<=', end)
      .orderBy('nextDate', 'desc')
      .orderBy('user.id', 'desc')
      .get()
      .then((res) => {
        return res.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
      });
  };
  getReservationsByUser = (uid) => {
    return this.firestore
      .collection('reservation')
      .where('user.id', '==', uid)
      .where('status', '==', RESERVE_STATUS.reserved)
      .orderBy('date', 'desc')
      .orderBy('begin', 'desc');
  };
  deleteAllSchedules = () => {
    return this.firestore
      .collection('schedule')
      .get()
      .then((snapSchedule) => {
        snapSchedule.docs.map((scheduleDoc) => {
          return scheduleDoc.ref.delete().then(() => {
            console.log('Successfully deleted schedule', scheduleDoc.id);
            scheduleDoc.ref
              .collection('reservation')
              .where('status', '==', RESERVE_STATUS.reserved)
              .get()
              .then((snapReservation) => {
                snapReservation.docs.map((reservationDoc) => {
                  reservationDoc.ref.delete().then(() => console.log('reservation deleted', reservationDoc.id));
                });
              });
          });
        });
      })
      .catch((err) => {
        console.log('deleteAllSchedules err', err);
      });
  };
  createReservation = (data) => {
    const create = this.function.httpsCallable('reservationFn-create');
    return create(data);
  };
  cancelReservation = (id) => {
    const cancel = this.function.httpsCallable('reservationFn-cancel');
    return cancel({ id });
  };
  deleteScheduleTime = (employeeId, date, time) => {
    const func = this.function.httpsCallable('reservationFn-deleteScheduleTime');
    return func({ employeeId, date, time });
  };
  createEmployeeSchedule = (data) => {
    const create = this.function.httpsCallable('reservationFn-createEmployeeSchedule');
    return create(data);
  };
  deleteEmployeeSchedule = (data) => {
    const delFunction = this.function.httpsCallable('reservationFn-deleteEmployeeSchedule');
    return delFunction(data);
  };
  updateReservation = (id, data) => {
    // TODO: RULES -> Only accept specific fields to update: examination, status,updatedAt
    console.log('id,data', id, data);
    return this.firestore
      .collection('reservation')
      .doc(id)
      .update({ ...data, updatedAt: firebase.firestore.FieldValue.serverTimestamp() });
  };
}
const instance = new ReservationService();

export default instance;
