export const MARK_TYPES = { 5: 5, 4: 4 };

// TEETH ROWS
export const TEETH = {
  row1: [
    {},
    {},
    {},
    { id: 55, markType: MARK_TYPES[5] },
    { id: 54, markType: MARK_TYPES[5] },
    { id: 53, markType: MARK_TYPES[4] },
    { id: 52, markType: MARK_TYPES[4] },
    { id: 51, markType: MARK_TYPES[4] },
    { id: 61, markType: MARK_TYPES[4] },
    { id: 62, markType: MARK_TYPES[4] },
    { id: 63, markType: MARK_TYPES[4] },
    { id: 64, markType: MARK_TYPES[5] },
    { id: 65, markType: MARK_TYPES[5] },
    {},
    {},
    {},
  ],
  row2: [
    { id: 18, markType: MARK_TYPES[5] },
    { id: 17, markType: MARK_TYPES[5] },
    { id: 16, markType: MARK_TYPES[5] },
    { id: 15, markType: MARK_TYPES[5] },
    { id: 14, markType: MARK_TYPES[5] },
    { id: 13, markType: MARK_TYPES[4] },
    { id: 12, markType: MARK_TYPES[4] },
    { id: 11, markType: MARK_TYPES[4] },
    { id: 21, markType: MARK_TYPES[4] },
    { id: 22, markType: MARK_TYPES[4] },
    { id: 23, markType: MARK_TYPES[4] },
    { id: 24, markType: MARK_TYPES[5] },
    { id: 25, markType: MARK_TYPES[5] },
    { id: 26, markType: MARK_TYPES[5] },
    { id: 27, markType: MARK_TYPES[5] },
    { id: 28, markType: MARK_TYPES[5] },
  ],
  row3: [
    { id: 48, markType: MARK_TYPES[5] },
    { id: 47, markType: MARK_TYPES[5] },
    { id: 46, markType: MARK_TYPES[5] },
    { id: 45, markType: MARK_TYPES[5] },
    { id: 44, markType: MARK_TYPES[5] },
    { id: 43, markType: MARK_TYPES[4] },
    { id: 42, markType: MARK_TYPES[4] },
    { id: 41, markType: MARK_TYPES[4] },
    { id: 31, markType: MARK_TYPES[4] },
    { id: 32, markType: MARK_TYPES[4] },
    { id: 33, markType: MARK_TYPES[4] },
    { id: 34, markType: MARK_TYPES[5] },
    { id: 35, markType: MARK_TYPES[5] },
    { id: 36, markType: MARK_TYPES[5] },
    { id: 37, markType: MARK_TYPES[5] },
    { id: 38, markType: MARK_TYPES[5] },
  ],
  row4: [
    {},
    {},
    {},
    { id: 85, markType: MARK_TYPES[5] },
    { id: 84, markType: MARK_TYPES[5] },
    { id: 83, markType: MARK_TYPES[4] },
    { id: 82, markType: MARK_TYPES[4] },
    { id: 81, markType: MARK_TYPES[4] },
    { id: 71, markType: MARK_TYPES[4] },
    { id: 72, markType: MARK_TYPES[4] },
    { id: 73, markType: MARK_TYPES[4] },
    { id: 74, markType: MARK_TYPES[5] },
    { id: 75, markType: MARK_TYPES[5] },
    {},
    {},
    {},
  ],
};
