import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/dashboard/index';
import '../scss/style.scss';
import { Provider } from 'react-redux';
import store from './store';
const AdminLayout = () => {
  return (
    <Provider store={store}>
      <AppSidebar />
      <div className='wrapper d-flex flex-column min-vh-100 bg-light'>
        <AppHeader />
        <div className='body flex-grow-1 px-3'>
          {/* <AppContent /> */}
          <Outlet />
        </div>
        <AppFooter />
      </div>
    </Provider>
  );
};

export default AdminLayout;
