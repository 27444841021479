import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

// import LangContext from '../context/Lang';
export const ProtectedRoute = ({ children, onlyAdmin = true, emailVerified = false }) => {
  const { user, handleToast } = useAuth();

  let location = useLocation();
  // Зөвхөн админ нэвтрэх шалгалт
  if (onlyAdmin && (!user || !user.isAdmin) && location.pathname !== '/login') {
    console.log('REDIRECT TO LOGIN from', location.pathname);

    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  // Нэвтрээгүй үед нэвтрэх дэлгэц руу шилжинэ
  if (!user && location.pathname !== '/login') {
    console.log('REDIRECT TO LOGIN from', location.pathname);

    return <Navigate to='/login' state={{ from: location }} replace />;
  }
  // Нэвтэрсэн үед имэйл баталгаажуулалт шаардлагатай дэлгэц дээр анхааруулга өгнө
  if (user && emailVerified && !user.emailVerified) {
    console.log('emailVerified from', location.pathname);

    handleToast('Та имэйл ээ баталгаажуулах шаардлагатай', 'error');

    return <Navigate to={'/dashboard/me/info'} state={{ from: location }} replace />;
  }

  return children;
};
