import './style.css';
import { TEETH } from './teeth';
import Tooth from './Tooth';

export default function OralCavity({ activeId, onClick = () => {}, reservation = {} }) {
  const teeth = reservation?.examination ? reservation?.examination?.teeth : [];
  // console.log('reservation', reservation, teeth, activeId);

  return (
    <div className='text-center'>
      <img style={{ width: '15vw' }} src='../../../assets/images/oral-structure.png' alt='oral img' />
      {activeId
        ? Object.keys(TEETH).map((key, i) => {
            const teethRow = TEETH[key];

            return (
              <div className='row'>
                {teethRow.map(({ id, markType }) => {
                  // Make sure current tooth is marked
                  const markedTooths = teeth.filter((obj) => obj.id === id);
                  const marks = {};
                  // Assign marked values to the position If exists
                  if (markedTooths.length > 0) {
                    markedTooths.forEach((el) => {
                      marks[el.position] = el.mark;
                    });
                  }

                  return (
                    <div className='tooth'>
                      {id && (
                        <Tooth
                          id={id}
                          lineNum={i}
                          type={markType}
                          marks={marks}
                          onClick={(position) => onClick(id, position)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })
        : null}
    </div>
  );
}
