import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import './style.css';
import { CCallout } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilDelete } from '@coreui/icons';
import CustomModal from '../../modals/Modals';
const MODAL_MODES = { create: 'create', delete: 'delete' };

const timelineLabels = (startTime, interval) => {
  const periodInMinutes = 14 * 60; // timeDiff(startTime, endTime);

  const timeLabels = [];
  const startTimeMoment = moment(startTime, 'HH:mm');
  for (let i = 0; i < periodInMinutes; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, 'm');
    timeLabels.push(startTimeMoment.format('HH:mm'));
  }

  return timeLabels;
};
const MODAL = {
  mode: MODAL_MODES.create,
  visible: false,
  title: '',
  text: '',
  data: { time: '' },
};
function DailySchedule({ handleDeleteScheduleTime = () => {}, currentDate, currentSchedule, currentReservation = [] }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(MODAL);

  const [timeline, setTimeline] = useState([]);
  const [isHovering, setIsHovering] = useState(-1);
  const handleMouseOver = (i) => setIsHovering(i);

  const handleMouseOut = () => setIsHovering(-1);

  useEffect(() => {
    // const times = timelineLabels('08:00', 30);
    // setTimeline(times);
    if (currentSchedule) {
      const timesArr = Object.keys(currentSchedule?.times || {})
        .map((key) => {
          return key;
        })
        .sort();
      setTimeline(timesArr);
    } else {
      setTimeline([]);
    }
  }, [currentSchedule]);

  const handleNavigationReservation = (uid, resId) => {
    if (uid && resId) {
      navigate({
        pathname: `/dashboard/reservation/users/${uid}?resId=${resId}`,
      });
    }
  };
  const handleConfirm = (time) => {
    setShowModal((prev) => {
      return {
        ...prev,
        title: 'Баталгаажуулалт',
        text: `Та "${currentDate}" өдрийн "${time}" цагийг устгахдаа итгэлтэй байна уу?`,
        visible: true,
        data: { time },
        mode: MODAL_MODES.delete,
      };
    });
  };

  const handleSubmit = () => {
    switch (showModal.mode) {
      case MODAL_MODES.create:
        break;

      case MODAL_MODES.delete:
        handleDeleteScheduleTime(showModal.data.time);
        break;

      default:
        return;
    }
  };

  // TODO:
  //  1. Delete time if not reserved
  //  2. Add time from input
  return (
    <div id='scheduleContainer'>
      <div className='table-responsive'>
        {timeline.length === 0 ? (
          <CCallout color='info'>Цагийн хувиар олдсонгүй</CCallout>
        ) : (
          <table className='table  table-calendar text-center'>
            <thead>
              <tr className='bg-light-gray'>
                <th className='text-uppercase'>Цаг</th>
                <th className='text-uppercase'>Захиалга</th>
                {/* <th className='text-uppercase'>Tuesday</th>
              <th className='text-uppercase'>Wednesday</th>
              <th className='text-uppercase'>Thursday</th>
              <th className='text-uppercase'>Friday</th>
              <th className='text-uppercase'>Saturday</th> */}
              </tr>
            </thead>

            <tbody>
              {timeline.map((time, i) => {
                // const reservationData = currentReservation.find((obj) => obj.begin === time);
                const reservationData = currentSchedule?.times ? currentSchedule.times[time] : {};
                const employeeId = currentSchedule?.employee?.id;

                const resId = reservationData ? `${currentDate}-${time}-${employeeId}` : null;

                const service = reservationData?.service || {};
                const { id, fname, lname, phone, email } = reservationData?.user
                  ? reservationData?.user
                  : { id: '', fname: '', lname: '', email: '', phone: '' };
                const beginTime = reservationData?.begin || '';

                return (
                  <tr>
                    <td
                      className='align-middle col-md-2'
                      onDoubleClick={() => console.log('DOUBLE')}
                      onMouseOver={() => handleMouseOver(i)}
                      onMouseLeave={handleMouseOut}
                    >
                      <span>{time} </span>
                      {!reservationData && isHovering === i && (
                        <CIcon
                          className='ml-3'
                          size='xl'
                          style={{ color: 'red' }}
                          icon={cilDelete}
                          onClick={() => {
                            handleConfirm(time);
                          }}
                        />
                      )}
                    </td>
                    <td className='col-md-10' onClick={() => handleNavigationReservation(id, resId)}>
                      {reservationData && (
                        <span className='bg-sky py-2 px-2 mx-1  border-radius-5  text-white'>{service.name}</span>
                      )}
                      <div>
                        {`${fname} ${lname}`} {phone}
                      </div>
                      <div className='small text-medium-emphasis'>{beginTime}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <CustomModal
          alignment='center'
          backdrop={false}
          title={showModal.title}
          text={showModal.text}
          setVisible={(visible) => {
            setShowModal((prev) => {
              return { ...prev, visible };
            });
          }}
          visible={showModal.visible}
          onSubmit={handleSubmit}
          // showFooter={false}
        ></CustomModal>
      </div>
    </div>
  );
}

export default DailySchedule;
