import React from 'react';
import { Link } from 'react-router-dom';

function CarouselItem({ title = '', paragraph = '', route = '/', active = false, buttonTxt = '' }) {
  return (
    <div className={`carousel-item ${active ? 'active' : ''}`}>
      <div className='container'>
        <div className='row'>
          <div className='col-md-7'>
            <div className='detail-box'>
              <h1>{title}</h1>
              <p>{paragraph}</p>
              <div className='btn-box'>
                {buttonTxt ? (
                  <Link className='btn1' to={route}>
                    <span> {buttonTxt}</span>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselItem;
