import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ProtectedRoute } from './components/auth/ProtectedRoute';
import Layout from './components/Layout';
import { AuthProvider, useAuth } from './context/AuthContext';
import AdminLayout from './layout/AdminLayout';
import About from './pages/About';
import Dashboard from './pages/admin/Dashboard';
import EmployeeList from './pages/admin/Employee';
import Schedule from './pages/admin/Employee/Schedule';
import CreateSchedule from './pages/admin/Employee/Schedule/Create';
import CreateReservation from './pages/admin/Reservation/Create';
import ListReservation from './pages/admin/Reservation/List';
import MedicalTreatment from './pages/admin/Reservation/Treatment';
import UserReservation from './pages/admin/Reservation/User';
import UserDetail from './pages/admin/Reservation/User/Detail';
import FilteredUsers from './pages/admin/Reservation/User/FilteredList';
import UserInformation from './pages/admin/Reservation/User/Info';
import Contact from './pages/Contact';
import Departments from './pages/Departments';
import Doctors from './pages/Doctors';
import Home from './pages/Home';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import CreateReservationUser from './pages/user/Reservation/Create';
import ListReservationUser from './pages/user/Reservation/List/index';
import MyDetail from './pages/user/Reservation/User/Detail';
import MyInfo from './pages/user/Reservation/User/Info';

function App() {
  const { user } = useAuth();
  // console.log('user', user);
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/department' element={<Departments />} />
            <Route path='/doctors' element={<Doctors />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<NotFound />} />
          </Route>

          <Route path='/dashboard' element={<AdminLayout />}>
            <Route
              index
              element={
                <ProtectedRoute onlyAdmin={false}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            {/* EMPLOYEES */}
            <Route path='employees'>
              <Route
                path='list'
                element={
                  <ProtectedRoute>
                    <EmployeeList />
                  </ProtectedRoute>
                }
              />
              <Route
                path='list/:employeeId/timetable'
                element={
                  <ProtectedRoute>
                    <Schedule />
                  </ProtectedRoute>
                }
              />
              <Route
                path='timetable/create'
                element={
                  <ProtectedRoute>
                    <CreateSchedule />
                  </ProtectedRoute>
                }
              />
              <Route
                path='users/:uid'
                element={
                  <ProtectedRoute>
                    <UserInformation />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* RESERVATION */}
            <Route path='reservation'>
              <Route
                path='list'
                element={
                  <ProtectedRoute>
                    <ListReservation />
                  </ProtectedRoute>
                }
              />
              <Route
                path='create'
                element={
                  <ProtectedRoute>
                    <CreateReservation />
                  </ProtectedRoute>
                }
              />
              <Route
                path='treatment'
                element={
                  <ProtectedRoute>
                    <MedicalTreatment />
                  </ProtectedRoute>
                }
              />
              <Route
                path='users'
                element={
                  <ProtectedRoute>
                    <UserReservation />
                  </ProtectedRoute>
                }
              />
              <Route
                path='users-filtered'
                element={
                  <ProtectedRoute>
                    <FilteredUsers />
                  </ProtectedRoute>
                }
              />
              <Route
                path='users/:uid'
                element={
                  <ProtectedRoute>
                    <UserDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* FOR USERS */}
            <Route path='me'>
              <Route
                path='reservation/create'
                element={
                  <ProtectedRoute onlyAdmin={false} emailVerified>
                    <CreateReservationUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path='reservation/list'
                element={
                  <ProtectedRoute onlyAdmin={false} emailVerified>
                    <ListReservationUser />
                  </ProtectedRoute>
                }
              />

              <Route
                path='reservation/detail'
                element={
                  <ProtectedRoute onlyAdmin={false} emailVerified>
                    <MyDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path='info'
                element={
                  <ProtectedRoute onlyAdmin={false}>
                    <MyInfo />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
