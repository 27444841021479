import React from 'react';

function CustomMarker({ text }) {
  return (
    <div className='mapMarker'>
      <img src='../../assets/images/marker.png' alt='marker' />
      {/* <button type='button' className='btn btn-secondary' data-toggle='tooltip' data-placement='top' title='Tooltip on top'>
        Tooltip on top
      </button> */}
    </div>
  );
}

export default CustomMarker;
