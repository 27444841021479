import { CButton, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react';
import React from 'react';
import { positions } from '../../../constants';
function EmployeeCreate({ form, setForm, validated, handleSubmit }) {
  const isPwdNotConfirmed = form.passwordConfirm.length === 0 || form.password !== form.passwordConfirm;
  return (
    <div>
      <CForm noValidate validated={validated} onSubmit={handleSubmit}>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Цахим шуудан
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='email'
              value={form.email}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, email: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>

        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Овог
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='text'
              value={form.lname}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, lname: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Нэр
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='text'
              value={form.fname}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, fname: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputPassword3' className='col-sm-2 col-form-label'>
            Ажлын байр
          </CFormLabel>
          <CCol sm={10}>
            <CFormSelect
              required
              type={'text'}
              label='State'
              value={form.position}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, position: e.target.value };
                })
              }
            >
              {positions.map((obj) => (
                <option key={obj.label} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            </CFormSelect>
          </CCol>
        </CRow>

        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputPassword3' className='col-sm-2 col-form-label'>
            Ажлын байр
          </CFormLabel>
          <CCol sm={10}>
            <CFormSelect required type={'text'} label='State'>
              {positions.map((obj) => (
                <option key={obj.label} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            </CFormSelect>
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputPassword3' className='col-sm-2 col-form-label'>
            Нууц үг
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='password'
              value={form.password}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, password: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputPassword3' className='col-sm-2 col-form-label'>
            Нууц үг давтах
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='password'
              value={form.passwordConfirm}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, passwordConfirm: e.target.value };
                })
              }
            />
            {isPwdNotConfirmed && form.passwordConfirm.length > 0 && (
              <p className='error'> Нууц үг хоорондоо таарахгүй байна </p>
            )}
          </CCol>
        </CRow>

        <CButton type='submit' disabled={isPwdNotConfirmed}>
          Бүртгэх
        </CButton>
      </CForm>
    </div>
  );
}

export default EmployeeCreate;
