import { CCol, CForm, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react';
import React from 'react';
import { treatmentOptions } from '../../../../constants';
const statusOptions = [
  { label: 'Идэвхитэй', value: 'true' },
  { label: 'Идэвхигүй', value: 'false' },
];

function CreateTreatment({ form, setForm }) {
  return (
    <div>
      <CForm>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Нэр
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='text'
              value={form.name}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, name: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>

        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Үнэ
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='number'
              placeholder='₮'
              value={form.price}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, price: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Төрөл
          </CFormLabel>
          <CCol sm={10}>
            <CFormSelect
              required
              value={form?.type?.value}
              onChange={(e) => {
                setForm((prev) => {
                  const data = treatmentOptions.find((obj) => obj.value === e.target.value);
                  return { ...prev, type: data };
                });
              }}
            >
              {treatmentOptions.map((obj) => (
                <option key={obj.label} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            </CFormSelect>
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Статус
          </CFormLabel>
          <CCol sm={10}>
            <CFormSelect
              required
              label='State'
              value={form.status}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, status: e.target.value === 'true' };
                })
              }
            >
              {statusOptions.map((obj) => (
                <option key={obj.label} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            </CFormSelect>
          </CCol>
        </CRow>
      </CForm>
    </div>
  );
}

export default CreateTreatment;
