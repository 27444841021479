import { CButton, CCol, CForm, CFormInput, CFormLabel, CFormTextarea, CRow } from '@coreui/react';
import React, { useState } from 'react';
import CustomMap from '../components/CustomMap';
import { useAuth } from '../context/AuthContext';
import fbAuthService from '../services/firebase/firebaseAuthService';
const FORM = { name: '', phone: '', email: '', text: '' };
function Contact({ coordinates = [], zoom = 13, defaultCenter = { lat: 47.895349, lng: 106.934055 } }) {
  const { setShowSpinner, handleToast } = useAuth();
  const [form, setForm] = useState(FORM);
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('FORM', form);

    setValidated(true);
    const tmpForm = e.currentTarget;
    if (tmpForm.checkValidity() === true) {
      console.log('submit', form);
      setShowSpinner(true);
      try {
        await fbAuthService.receiveEmail(form);
        console.log('SUCCEED');
        setForm(FORM);
        handleToast('Амжилттай илгээлээ');
      } catch (err) {
        console.log('SUBMIT ERR', err);
        handleToast(err.message, 'error');
      } finally {
        setShowSpinner(false);
      }
    }
  };
  return (
    <div>
      <section className='contact_section layout_padding'>
        <div className='container'>
          <div className='heading_container'>
            <h2>Холбоо барих</h2>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form_container contact-form'>
                <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                  <CRow className='form-row'>
                    <CCol lg={6}>
                      <CFormInput
                        required
                        placeholder='Таны нэр'
                        type='text'
                        value={form.name}
                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                      />
                    </CCol>
                    <CCol lg={6}>
                      <CFormInput
                        required
                        type='tel'
                        pattern='[0-9]{8}'
                        placeholder='Утасны дугаар'
                        value={form.phone}
                        onChange={(e) => setForm({ ...form, phone: e.target.value })}
                      />
                    </CCol>
                  </CRow>
                  <CRow className='form-row'>
                    <CCol lg={12}>
                      <CFormInput
                        required
                        placeholder='Имэйл'
                        type='email'
                        value={form.email}
                        onChange={(e) => setForm({ ...form, email: e.target.value })}
                      />
                    </CCol>
                  </CRow>
                  <CRow className='form-row'>
                    <CCol lg={12}>
                      <input
                        type='text'
                        className='message-box'
                        placeholder='Текст'
                        value={form.text}
                        onChange={(e) => setForm({ ...form, text: e.target.value })}
                      />
                    </CCol>
                  </CRow>
                  <CRow className='form-row'>
                    <CCol lg={12}>
                      <button>Илгээх</button>
                    </CCol>
                  </CRow>
                </CForm>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='map_container'>
                <div className='map'>
                  <CustomMap coordinates={coordinates} zoom={zoom} defaultCenter={defaultCenter} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{' '}
    </div>
  );
}

export default Contact;
