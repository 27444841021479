import { CButton, CCol, CForm, CFormCheck, CFormInput, CFormLabel, CFormTextarea, CRow } from '@coreui/react';
import React from 'react';

const GENDER = [
  { label: 'Эр', value: 'male' },
  { label: 'Эм', value: 'female' },
];
export default function CreateUser({ form, setForm, handleSubmit, validated }) {
  return (
    <div>
      <CForm noValidate validated={validated} onSubmit={handleSubmit}>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Цахим шуудан
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              placeholder='you@mail.com'
              type='email'
              value={form.email}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, email: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>

        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Утас
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='tel'
              pattern='[0-9]{8}'
              placeholder='99001122'
              value={form.phone}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, phone: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Овог
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='text'
              placeholder='Овог'
              value={form.lname}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, lname: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Нэр
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='text'
              placeholder='Нэр'
              value={form.fname}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, fname: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Хүйс
          </CFormLabel>
          <CCol sm={10}>
            {GENDER.map((obj, i) => (
              <CFormCheck
                required
                inline
                id={'genderCheck' + i}
                type='radio'
                name='gender'
                value={obj.value}
                label={obj.label}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, gender: e.target.value };
                  })
                }
              />
            ))}
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Төрсөн огноо
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='date'
              value={form.birthday}
              pattern='\d{4}-\d{2}-\d{2}'
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, birthday: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Нэмэлт мэдээлэл
          </CFormLabel>
          <CCol sm={10}>
            <CFormTextarea
              type='text'
              value={form.note}
              placeholder='...'
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, note: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        {/* <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Зураг
          </CFormLabel>
          <CCol sm={10}>
          
            <CFormInput
              required
              type='file'
              value={form.photoURL}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, photoURL: e.target.value };
                })
              }
            />
          </CCol>
        </CRow> */}
        <CButton color='primary' type='submit'>
          Submit form
        </CButton>
      </CForm>
    </div>
  );
}
