const ls = window.localStorage;
const localStorageService = {
  setItem: (key, value) => {
    value = JSON.stringify(value);
    ls.setItem(key, value);
    return true;
  },

  getItem: (key) => {
    let value = ls.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  },
};

export default localStorageService;
