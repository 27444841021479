import { CCol, CRow } from '@coreui/react';
import moment from 'moment';
import React from 'react';
import helperService from '../../services/helperService';
import ActiveButton from './ActiveButton';

export default function FormTime({ dailyDrSchedules = [], onClick, selectedDate, beginTime, employee }) {
  // Эмчийн цаг нь объякт төрөлтэй ирж байгааг arr болгоод filter хийх
  const handleFilterTimes = (times) => {
    if (!times) return [];
    const timesArr = Object.keys(times)
      .map((time) => {
        return { data: times[time], begin: time };
      })
      .sort((a, b) => (a.begin > b.begin ? 1 : -1));
    // Тухайн цаг дээр захиалга хийгдсэн эсвэл цаг нь өнгөрөөгүй эсэхийг шалгана
    // console.log('timesArr', times, timesArr);

    return timesArr.filter(
      (timeObj) => timeObj.data === '' && helperService.isDatetimeBefore(`${selectedDate} ${timeObj.begin}`)
    );
  };

  return (
    <div>
      {/* Тухайн сонгосон өдрийн эмчийн захиалгийн цагнууд */}
      {dailyDrSchedules?.map((dailySchedule, i) => {
        const possibleTimes = handleFilterTimes(dailySchedule?.times);

        return (
          <div key={'day' + i}>
            <h6>{dailySchedule.employee?.displayName}</h6>
            <CRow>
              {possibleTimes.length > 0 ? (
                possibleTimes.map((time, j) => {
                  return (
                    <CCol md={2} key={'times' + j}>
                      <ActiveButton
                        className='my-1'
                        name={time.begin + '~'}
                        isActive={beginTime === time.begin && dailySchedule.employee?.id === employee?.id}
                        // Тухайн сонгосон цагны эмч болон эхлэх цаг дамжуулна
                        onClick={() => {
                          onClick(dailySchedule.employee, time.begin);
                        }}
                      />
                    </CCol>
                  );
                })
              ) : (
                <div className='alert alert-warning' role='alert'>
                  Боломжит цаг олдсонгүй!
                </div>
              )}
            </CRow>
          </div>
        );
      })}
    </div>
  );
}
