// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAtTubO7az3KeG7uNKvu8wUb-rhjNVVWuc',
  authDomain: 'dental-clinic-80704.firebaseapp.com',
  projectId: 'dental-clinic-80704',
  storageBucket: 'dental-clinic-80704.appspot.com',
  messagingSenderId: '1029325260795',
  appId: '1:1029325260795:web:6701172c76d2b67fd7ed5e',
  measurementId: 'G-NHCBZYGJ3W',
};
export default firebaseConfig;
