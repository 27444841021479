import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
function Layout(props) {
  let location = useLocation();
  const [year, setYear] = useState(new Date().getFullYear());

  return (
    <div>
      <Header path={location.pathname} />

      <Outlet />

      {/* <!-- footer section --> */}
      <footer className='footer_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-lg-6   footer_col'>
              <div className='footer_contact'>
                <h4>Бидэнтэй холбогдох</h4>
                <div className='contact_link_box'>
                  <a href='#/'>
                    <i className='fa fa-map-marker' aria-hidden='true'></i>
                    <span> Хаяг: УБ хот Хан-Уул дүүрэг 17-р хороо, 7-р хэсэг, Маршил таун 100-7тоот</span>
                  </a>

                  <a href='#/'>
                    <i className='fa fa-phone' aria-hidden='true'></i>
                    <span> Утас: 91059111 </span>
                  </a>
                  <a href='#/'>
                    <i className='fa fa-envelope' aria-hidden='true'></i>
                    <span> support@mishka.mn </span>
                  </a>
                </div>
              </div>
              <div className='footer_social'>
                <a href='#/'>
                  <i className='fa fa-facebook' aria-hidden='true'></i>
                </a>
                <a href='#/'>
                  <i className='fa fa-twitter' aria-hidden='true'></i>
                </a>
                <a href='#/'>
                  <i className='fa fa-linkedin' aria-hidden='true'></i>
                </a>
                <a href='#/'>
                  <i className='fa fa-instagram' aria-hidden='true'></i>
                </a>
              </div>
            </div>

            {/* <div className='col-md-6 col-lg-3 footer_col'>
              <div className='footer_detail'>
                <h4>About</h4>
                <p>
                  Beatae provident nobis mollitia magnam voluptatum, unde dicta facilis minima veniam corporis
                  laudantium alias tenetur eveniet illum reprehenderit fugit a delectus officiis blanditiis ea.
                </p>
              </div>
            </div>

            <div className='col-md-6 col-lg-3 footer_col'>
              <h4>Newsletter</h4>
              <form action='#'>
                <input type='email' placeholder='Enter email' />
                <button type='submit'>Subscribe</button>
              </form>
            </div> */}
          </div>
          <div className='footer-info'>
            <p>
              Зохиогчийн эрх хуулиар хамгаалагдсан &copy; <span>{year} он</span>
            </p>
          </div>
        </div>
      </footer>
      {/* <!-- footer section --> */}
    </div>
  );
}

export default Layout;
