import React, { useEffect, useState } from 'react';
import { createSearchParams, Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import localStorageService from '../services/localStorageService';
import userService from '../services/firebase/userService';
import { CCol, CForm, CFormCheck, CFormInput, CFormLabel, CRow } from '@coreui/react';
const MODES = { signin: 'signin', signup: 'signup' };
const GENDER = [
  { label: 'Эр', value: 'male' },
  { label: 'Эм', value: 'female' },
];
function Login(props) {
  const { user, handleLogin, setShowSpinner, handleToast } = useAuth();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode'); // "testCode"
  useEffect(() => {
    const email = localStorageService.getItem('loginEmail');

    setForm({ ...form, email, rememberMe: email ? true : false });
  }, []);

  const [form, setForm] = useState({ email: '', pwd: '', pwdConfirm: '', rememberMe: false });
  if (user) {
    console.log('REDIRECT TO HOME');
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/' />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('FORM', form);
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    const tmpForm = e.currentTarget;

    if (tmpForm.checkValidity() === true) {
      console.log('submit', form);

      setShowSpinner(true);
      try {
        if (mode === MODES.signup) {
          await userService.createUserSelf({ ...form, displayName: `${form.lname} ${form.fname}` });
          console.log('SUCCEED');
          handleToast('Хэрэглэгч амжилттай үүсгэлээ');
          switchMode();
        } else {
          handleLogin(form);
        }
      } catch (err) {
        console.log('SUBMIT ERR', err);
        handleToast(err.message, 'error');
      } finally {
        setShowSpinner(false);
      }
    }

    // const auth = mode === MODES.signup ? handleSignup : handleLogin;
    // auth(form);
  };
  const switchMode = (e) =>
    navigate({
      pathname: '/login',
      search: createSearchParams({
        mode: mode === MODES.signup ? MODES.signin : MODES.signup,
      }).toString(),
    });
  return (
    <div className='container layout_padding'>
      <div className='col-12 text-center mb-4'>
        <h1>Нэвтрэх</h1>
      </div>
      <div className='col-sm-5 m-auto'>
        {/* <form id='login-form' onSubmit={handleSubmit}> */}
        <CForm noValidate validated={validated} onSubmit={handleSubmit}>
          <div className='row text-left'>
            <div className='col-sm-12 form-group' style={{ marginBottom: '1rem' }}>
              <label style={{ marginBottom: '0.5rem' }} htmlFor='email'>
                Цахим шуудан
              </label>
              <CFormInput
                required
                autoComplete='off'
                value={form.email}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, email: e.target.value };
                  })
                }
                type='email'
                id='email'
                className='form-control form-control-lg'
              />
            </div>
            {mode === MODES.signup && (
              <>
                <div className='col-sm-12 form-group' style={{ marginBottom: '1rem' }}>
                  <label style={{ marginBottom: '0.5rem' }} htmlFor='email'>
                    {' '}
                    Утас
                  </label>

                  <CFormInput
                    required
                    type='tel'
                    pattern='[0-9]{8}'
                    placeholder='99001122'
                    value={form.phone}
                    onChange={(e) =>
                      setForm((prev) => {
                        return { ...prev, phone: e.target.value };
                      })
                    }
                  />
                </div>
                <div className='col-sm-12 form-group' style={{ marginBottom: '1rem' }}>
                  <label style={{ marginBottom: '0.5rem' }} htmlFor='email'>
                    {' '}
                    Овог
                  </label>
                  <CFormInput
                    required
                    type='text'
                    placeholder='Овог'
                    value={form.lname}
                    onChange={(e) =>
                      setForm((prev) => {
                        return { ...prev, lname: e.target.value };
                      })
                    }
                  />
                </div>
                <div className='col-sm-12 form-group' style={{ marginBottom: '1rem' }}>
                  <label style={{ marginBottom: '0.5rem' }} htmlFor='email'>
                    {' '}
                    Нэр
                  </label>

                  <CFormInput
                    required
                    type='text'
                    placeholder='Нэр'
                    value={form.fname}
                    onChange={(e) =>
                      setForm((prev) => {
                        return { ...prev, fname: e.target.value };
                      })
                    }
                  />
                </div>
                <div className='col-sm-12 form-group' style={{ marginBottom: '1rem' }}>
                  <label style={{ marginBottom: '0.5rem' }} htmlFor='email'>
                    Хүйс
                  </label>
                  <p>
                    {GENDER.map((obj, i) => (
                      <CFormCheck
                        required
                        inline
                        id={'genderCheck' + i}
                        type='radio'
                        name='gender'
                        value={obj.value}
                        label={obj.label}
                        onChange={(e) =>
                          setForm((prev) => {
                            return { ...prev, gender: e.target.value };
                          })
                        }
                      />
                    ))}
                  </p>
                </div>
                <div className='col-sm-12 form-group' style={{ marginBottom: '1rem' }}>
                  <label style={{ marginBottom: '0.5rem' }} htmlFor='email'>
                    {' '}
                    Төрсөн огноо
                  </label>

                  <CFormInput
                    required
                    type='date'
                    value={form.birthday}
                    pattern='\d{4}-\d{2}-\d{2}'
                    onChange={(e) =>
                      setForm((prev) => {
                        return { ...prev, birthday: e.target.value };
                      })
                    }
                  />
                </div>
              </>
            )}
            <div className='col-sm-12 form-group' style={{ marginBottom: '1rem' }}>
              <label style={{ marginBottom: '0.5rem' }} htmlFor='password'>
                Нууц үг
              </label>
              <input
                required
                value={form.pwd}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, pwd: e.target.value };
                  })
                }
                type='password'
                id='password'
                className='form-control form-control-lg'
              />
            </div>
            {mode === MODES.signup && (
              <div className='col-sm-12 form-group' style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '0.5rem' }} htmlFor='password'>
                  Нууц үг давтах
                </label>
                <input
                  required
                  value={form.pwdConfirm}
                  onChange={(e) =>
                    setForm((prev) => {
                      return { ...prev, pwdConfirm: e.target.value };
                    })
                  }
                  type='password'
                  id='password'
                  className='form-control form-control-lg'
                />
                {form.pwdConfirm.length > 0 && form.pwd !== form.pwdConfirm && (
                  <p className='error'> Нууц үг хоорондоо таарахгүй байна </p>
                )}
              </div>
            )}
            <div className='col-sm-12 text-center form-group' style={{ marginBottom: '1rem' }}>
              {mode !== MODES.signup && (
                <div className='form-group form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='check'
                    // value={form.rememberMe}
                    checked={form.rememberMe}
                    onChange={(e) =>
                      setForm((prev) => {
                        return { ...prev, rememberMe: !prev.rememberMe };
                      })
                    }
                  />
                  <label className='form-check-label' htmlFor='check'>
                    Нэвтрэх нэр сануулах
                  </label>
                </div>
              )}
              <button
                type='submit'
                className='btn btn-lg btn-primary px-4'
                // disabled={mode === MODES.signup && form.pwd !== form.pwdConfirm}
              >
                <span>{mode === MODES.signup ? 'Бүртгэх' : 'Нэвтрэх'}</span>
              </button>
            </div>
            <div className='col-sm-12 text-center form-group mt-5' style={{ marginBottom: '1rem' }}>
              <p>
                <a href='#' onClick={switchMode}>
                  <span>{mode === MODES.signup ? 'Нэвтрэх' : 'Шинээр бүртгүүлэх'}</span>
                </a>
              </p>
            </div>
          </div>
        </CForm>
      </div>
    </div>
  );
}

export default Login;
