import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons';

import { AppHeaderDropdown } from './header/index';
import icon from '../../assets/brand/icon.jpg';

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  return (
    <CHeader position='sticky' className='mb-4'>
      <CContainer fluid>
        <CHeaderToggler className='ps-1' onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}>
          <CIcon icon={cilMenu} size='lg' />
        </CHeaderToggler>
        <CHeaderBrand className='mx-auto d-md-none' to='/'>
          {/* <CIcon icon={logo} height={48} alt='Logo' /> */}
          <img width={50} height={50} alt='logo' className='sidebar-brand-full' src={icon} />
          <h4 style={{ marginLeft: 5 }}>Sakura Dent</h4>
        </CHeaderBrand>
        {/* <CHeaderNav className='d-none d-md-flex me-auto'>
          <CNavItem>
            <CNavLink to='/dashboard' component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href='#'>Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href='#'>Settings</CNavLink>
          </CNavItem>
        </CHeaderNav> */}
        {/* <CHeaderNav>
          <CNavItem>
            <CNavLink href='#'>
              <CIcon icon={cilBell} size='lg' />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href='#'>
              <CIcon icon={cilList} size='lg' />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href='#'>
              <CIcon icon={cilEnvelopeOpen} size='lg' />
            </CNavLink>
          </CNavItem>
        </CHeaderNav> */}
        <CHeaderNav className='ms-3'>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
    </CHeader>
  );
};

export default AppHeader;
