import { cilClock, cilDelete, cilList, cilPen, cilPeople } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CustomModal from '../../../../components/modals/Modals';
import { treatmentOptions, treatmentTypes } from '../../../../constants';
import { useAuth } from '../../../../context/AuthContext';
import service from '../../../../services/firebase/reservationService';
import CreateTreatment from './Create';
const MODAL_MODES = { create: 'create', delete: 'delete', edit: 'edit' };
const MODAL = {
  mode: MODAL_MODES.create,
  visible: false,
  title: '',
  text: '',
  itemId: '',
  itemName: '',
};
const FORM = {
  name: '',
  price: 0,
  status: true,
  type: treatmentOptions[0],
  createdAt: '',
  createdBy: '',
};
export default function MedicalTreatment() {
  const { handleToast, setShowSpinner } = useAuth();

  const [showModal, setShowModal] = useState(MODAL);

  const [form, setForm] = useState(FORM);
  const [treatments, setTreatments] = useState([]);

  useEffect(() => {
    const sub = service.getTreatmentsSnap(setTreatments);
    return () => {
      sub();
    };
  }, []);

  const handleCreate = async () => {
    const isSubmitDisabled = !form.name || !form.price || !form.status || !form.type?.value;
    console.log('form', form);
    if (isSubmitDisabled) return handleToast('Форм гүйцэд бөглөнө үү', 'error');

    // console.log('MODAL', form);
    try {
      setShowSpinner(true);
      await service.createTreatment(form);

      handleToast('Эмчилгээ амжилттай үүслээ');
    } catch (error) {
      console.log('ERROR', error);
      handleToast('Алдаа гарлаа', 'error');
    } finally {
      setShowSpinner(false);
    }
  };

  const handleEdit = (id) => {
    console.log('Form', id, form);
    if (!form.name || !form.price || !form.status || !form.type)
      return handleToast('Талбар гүйцэт бөглөнө үү', 'error');
    setShowSpinner(true);
    service
      .editTreatment(id, form)
      .then(() => {
        handleToast('Үйлдэл амжилттай');
      })
      .catch((err) => {
        handleToast('Алдаа гарлаа', 'error');
      })
      .finally(() => setShowSpinner(false));
  };
  const handleDelete = (id) => {
    service
      .deleteTreatment(id)
      .then(() => {
        handleToast('Үйлдэл амжилттай');
      })
      .catch((err) => {
        handleToast('Алдаа гарлаа', 'error');
      });
  };

  const handleSubmit = () => {
    switch (showModal.mode) {
      case MODAL_MODES.create:
        handleCreate();
        break;

      case MODAL_MODES.delete:
        handleDelete(showModal.itemId);
        break;
      case MODAL_MODES.edit:
        handleEdit(showModal.itemId);
        break;

      default:
        return;
    }
  };
  return (
    <div>
      <CCard className='mb-4'>
        <CCardHeader>
          <CButton
            type='submit'
            onClick={() => {
              setForm({ ...FORM });
              setShowModal({ mode: MODAL_MODES.create, visible: true, title: 'Эмчилгээ үүсгэх', text: '' });
            }}
          >
            Үйлчилгээний төрөл үүсгэх
          </CButton>
        </CCardHeader>

        <CCardBody>
          <CTable align='middle' className='mb-0 border' hover responsive>
            <CTableHead color='light'>
              <CTableRow>
                <CTableHeaderCell>
                  <CIcon icon={cilList} />
                </CTableHeaderCell>

                <CTableHeaderCell>Нэр</CTableHeaderCell>
                <CTableHeaderCell>Үнэ</CTableHeaderCell>
                <CTableHeaderCell>Төрөл</CTableHeaderCell>
                <CTableHeaderCell>Статус</CTableHeaderCell>
                <CTableHeaderCell>Үүсгэсэн огноо</CTableHeaderCell>
                <CTableHeaderCell>Үүсгэсэн хүн</CTableHeaderCell>
                <CTableHeaderCell>Үйлдэл</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {treatments.map((item, index) => (
                <CTableRow v-for='item in tableItems' key={index}>
                  <CTableDataCell>
                    <div>{index + 1}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{item.name}</div>
                  </CTableDataCell>

                  <CTableDataCell>
                    <div className='clearfix'>
                      <div className='float-start'>{item.price}₮</div>
                    </div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{item?.type?.label}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{item.status ? 'Идэвхитэй' : 'Идэвхигүй'}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{moment(item.createdAt).fromNow()}</div>
                  </CTableDataCell>

                  <CTableDataCell>
                    <div>{item.createdByName}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButtonGroup role='group' aria-label='Basic example'>
                      <CButton
                        color='info'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() => {
                          console.log('edit');
                          setForm({ ...item });
                          setShowModal({
                            visible: true,
                            mode: MODAL_MODES.edit,
                            itemId: item.id,
                            itemName: item.name,
                            title: 'Засварлах',
                            text: ``,
                          });
                        }}
                      >
                        <CIcon icon={cilPen} size='xl' />
                      </CButton>
                      <CButton
                        color='danger'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() =>
                          setShowModal({
                            visible: true,
                            mode: MODAL_MODES.delete,
                            itemId: item.id,
                            itemName: item.name,
                            title: 'Баталгаажуулах',
                            text: `Та "${item.name}"-г устгахдаа итгэлтэй байна уу?`,
                          })
                        }
                      >
                        <CIcon icon={cilDelete} size='xl' style={{ color: 'red' }} />
                      </CButton>
                      {/* 
                <CButton color='success' variant='ghost' shape='rounded-pill'>
                  Success
                </CButton> */}
                    </CButtonGroup>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
      <CustomModal
        title={showModal.title}
        text={showModal.text}
        setVisible={(visible) =>
          setShowModal((prev) => {
            return { ...prev, visible };
          })
        }
        visible={showModal.visible}
        onSubmit={handleSubmit}
      >
        {(showModal.mode === MODAL_MODES.create || showModal.mode === MODAL_MODES.edit) && (
          <CreateTreatment form={form} setForm={setForm} />
        )}
      </CustomModal>
    </div>
  );
}
