import React from 'react';
import Doctor from '../components/Doctor';
// const doctors = [
//   {
//     name: 'Батсайхан Мөнхнасан',
//     position: 'Ерөнхий эмч',
//     img: 'https://www.mishka.mn/01_MoojiiDental/img/bg-img/9.png',
//   },
//   {
//     name: 'Мөнх-Эрдэнэ Билгүүнтөгс',
//     position: 'Арын алба',
//     img: 'https://www.mishka.mn/01_MoojiiDental/img/bg-img/10.png',
//   },
// ];
function Doctors({ doctors = [], title = '', text = '' }) {
  return (
    <div>
      <section className='doctor_section layout_padding'>
        <div className='container'>
          <div className='heading_container heading_center'>
            <h2>{title}</h2>
            <p className='col-md-10 mx-auto px-0'>{text}</p>
          </div>
          <div className='row'>
            {doctors.map((doctor, i) => {
              return (
                // <div className='col-sm-6 col-lg-4 mx-auto'>
                <div className='col-sm-6 col-lg-4 ' key={'doctor' + i}>
                  <Doctor {...doctor} />
                </div>
              );
            })}

            {/* <div className='col-sm-6 col-lg-4 mx-auto'>
              <div className='box'>
                <div className='img-box'>
                  <img src='../../assets/images/d3.jpg' alt='' />
                </div>
                <div className='detail-box'>
                  <div className='social_box'>
                    <a href=''>
                      <i className='fa fa-facebook' aria-hidden='true'></i>
                    </a>
                    <a href=''>
                      <i className='fa fa-twitter' aria-hidden='true'></i>
                    </a>
                    <a href=''>
                      <i className='fa fa-youtube' aria-hidden='true'></i>
                    </a>
                    <a href=''>
                      <i className='fa fa-linkedin' aria-hidden='true'></i>
                    </a>
                  </div>
                  <h5>Mia Mike</h5>
                  <h6 className=''>Doctor</h6>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className='btn-box'>
            <a href=''>View All</a>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Doctors;
