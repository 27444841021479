import { CCard, CCardBody, CCardHeader, CListGroup, CListGroupItem } from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useParams } from 'react-router-dom';
import DailySchedule from '../../../../components/calendar/DailySchedule';
import TileDescription from '../../../../components/calendar/Description';
import TileContent from '../../../../components/calendar/TileContent';
import { useAuth } from '../../../../context/AuthContext';
import reservationService from '../../../../services/firebase/reservationService';
import helperService from '../../../../services/helperService';
import './style.css';
const TILE_DESC = [
  { label: 'Өнөөдрийн огноо', type: 'now', text: '1', class: 'react-calendar__tile--now' },
  { label: 'Сонгогдсон огноо', type: 'now', text: '1', class: 'react-calendar__tile--active' },
  { label: 'Цаг захиалгатай огноо', type: 'now', text: '1', class: 'customCalendarTile' },
];
function Schedule(props) {
  const { employeeId } = useParams();
  const { setShowSpinner, handleToast } = useAuth();
  const [reservations, setReservations] = useState([]);
  const [currentReservation, setCurrentReservation] = useState([]);
  // Сонгосон өдрүүдийн эмч нарийн үзлэгийн цагнууд
  const [dailySchedules, setDailySchedules] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const tileClassName = ({ date, view }) => {
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      const dateReservations = reservations.filter((obj) => helperService.isSameDay(obj.date, date));
      if (dateReservations.length > 0) {
        return 'customCalendarTile';
      }
    }
  };
  const tileContent = ({ date, view }) => {
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      // if (datesToAddContentTo.find((dDate) => isSameDay(dDate, date))) {
      // console.log('date, view', date, view);
      const dateReservations = reservations.filter((obj) => helperService.isSameDay(obj.date, date));
      if (dateReservations.length > 0) {
        return <TileContent data={dateReservations} />;
      }
    }
  };
  useEffect(() => {
    // fbService.firestore.collection('schedule')
    const sub = reservationService
      .getReservationByDoctor(employeeId)
      .where('date', '>=', moment(new Date()).format('YYYY-MM-DD'))
      .onSnapshot((snapshot) => {
        const reservations = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setReservations(reservations);
      });
    return () => {
      sub && sub();
    };
  }, []);
  useEffect(() => {
    handleDateChange(selectedDate);
  }, [selectedDate, reservations]);

  const handleDateChange = (selectedDate) => {
    // console.log('selectedDate', selectedDate);
    setSelectedDate(selectedDate);
    const currentDate = moment(selectedDate).format('YYYY-MM-DD');
    // Filter current reservations
    const currentReservation = reservations.filter((obj) => obj.date === currentDate);
    setCurrentReservation(currentReservation);

    // Fetch schedule times
    if (!dailySchedules[currentDate]) {
      // Fetch reservation schedule if not exists
      setShowSpinner(true);
      reservationService
        .getScheduleByEmployee(currentDate, employeeId)
        .then((snap) => {
          console.log('snap', snap.exists, snap.ref.path);
          if (!snap.exists) return;
          setDailySchedules((prev) => {
            return { ...prev, [currentDate]: { ...snap.data(), id: snap.id } };
          });
        })
        .catch((err) => {
          console.log('ERR', err.message);
          handleToast(err.message, 'error');
        })
        .finally(() => setShowSpinner(false));
    }
  };
  const fetchSchedule = () => {
    // Fetch reservation schedule if not exists
    setShowSpinner(true);
    reservationService
      .getScheduleByEmployee(currentDate, employeeId)
      .then((snap) => {
        console.log('snap', snap.exists, snap.ref.path);
        if (!snap.exists) return;
        setDailySchedules((prev) => {
          return { ...prev, [currentDate]: { ...snap.data(), id: snap.id } };
        });
      })
      .catch((err) => {
        console.log('ERR', err.message);
        handleToast(err.message, 'error');
      })
      .finally(() => setShowSpinner(false));
  };

  const currentDate = moment(selectedDate).format('YYYY-MM-DD');

  const currentSchedule = dailySchedules[currentDate] || {};
  console.log('SCHEDULE', currentSchedule, currentReservation);

  const handleDeleteScheduleTime = async (time) => {
    setShowSpinner(true);
    try {
      await reservationService.deleteScheduleTime(employeeId, currentDate, time);
      console.log('SUCCEED');
      fetchSchedule();
      handleToast('Үйлдэл амжилттай');
    } catch (err) {
      console.log('SUBMIT ERR', err);
      handleToast(err.message, 'error');
    } finally {
      setShowSpinner(false);
    }
  };
  return (
    <CCard className='mb-4'>
      <CCardHeader>
        <h4>Нийт цаг захиалга: {currentReservation.length}</h4>
      </CCardHeader>
      <CCardBody>
        {/* <div className='scheduleContainer'> */}
        <div className='row'>
          <section className='col-md-4'>
            <Calendar
              className={['shadow p-3 mb-5 bg-white rounded', 'border-0', 'w-100']}
              onChange={(value, event) => handleDateChange(value)}
              value={selectedDate}
              tileContent={tileContent}
              tileClassName={tileClassName}
            />
            <TileDescription data={TILE_DESC} />
          </section>
          <section className='col-md-8'>
            <DailySchedule
              reservations={reservations}
              currentDate={currentDate}
              currentReservation={currentReservation}
              currentSchedule={currentSchedule}
              handleDeleteScheduleTime={handleDeleteScheduleTime}
            />
          </section>
        </div>
      </CCardBody>
      {/* </div> */}
    </CCard>
  );
}

export default Schedule;
