import { CListGroup, CListGroupItem } from '@coreui/react';
import React from 'react';

export default function TileDescription({ data = [] }) {
  return (
    <div className='react-calendar vw-100' style={{ borderColor: '#fff' }}>
      <CListGroup>
        {data.map((obj) => {
          return (
            <CListGroupItem className='d-flex justify-content-between align-items-center'>
              <div className={`${obj.class} react-calendar__tile`} style={{ width: 75, height: 38 }}>
                {obj.text}
              </div>
              <span>{obj.label}</span>
            </CListGroupItem>
          );
        })}
      </CListGroup>
    </div>
  );
}
