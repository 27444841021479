import {
  CAlert,
  CAlertLink,
  CButton,
  CCallout,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../context/AuthContext';
import fbAuthService, { fb } from '../../../../services/firebase/firebaseAuthService';
import service from '../../../../services/firebase/userService';

const GENDER = [
  { label: 'Эр', value: 'male' },
  { label: 'Эм', value: 'female' },
];
const LINK_TYPES = { password: 'password', emailVerification: 'emailVerification' };
export default function UserInformation() {
  const { handleToast, setShowSpinner, getCurrentUserData } = useAuth();
  const { uid } = useParams();

  const [user, setUser] = useState({});
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    // handleEmailVerification(true);
    if (uid) {
      fbAuthService
        .getUserData(uid)
        .then((res) => {
          console.log('user', res);
          setForm(res);
          setUser(res);
        })
        .catch((err) => {
          console.log('getUserData err', err);
          handleToast('Алдаа гарлаа', 'error');
        });
    }
  }, [uid]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const tmpForm = event.currentTarget;
    if (tmpForm.checkValidity() === true) {
      console.log('submit', form);

      setShowSpinner(true);
      try {
        delete form.photoURL;

        await service.updateUser({ ...form, displayName: `${form.lname} ${form.fname}` });

        console.log('SUCCEED');
        handleToast('Хэрэглэгч амжилттай шинэчлэлээ');
        setValidated(false);
      } catch (err) {
        console.log('SUBMIT ERR', err);
        handleToast(err.message, 'error');
      } finally {
        setShowSpinner(false);
      }
    }
  };
  const handleResetPassword = async () => {
    try {
      setShowSpinner(true);
      await service.sendLink({ uid, email: user.email, type: LINK_TYPES.password });
      handleToast('Нууц үг сэргээх линк илгээсэн');
    } catch (err) {
      console.log('SUBMIT ERR', err);
      handleToast(err.message, 'error');
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <div>
      <CForm noValidate validated={validated} onSubmit={handleSubmit}>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Цахим шуудан
          </CFormLabel>

          <CCol sm={10}>
            <CInputGroup>
              <CFormInput
                required
                disabled
                placeholder='you@mail.com'
                type='email'
                value={form.email}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, email: e.target.value };
                  })
                }
              />
              <CButton
                type='button'
                color={'info'}
                variant='outline'
                id='inputGroupFileAddon04'
                onClick={handleResetPassword}
              >
                Нууц үг сэргээх линк илгээх
              </CButton>
            </CInputGroup>
          </CCol>
        </CRow>

        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Утас
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='tel'
              pattern='[0-9]{8}|\+976[0-9]{8}'
              placeholder='99001122'
              value={form.phone}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, phone: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Овог
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='text'
              placeholder='Овог'
              value={form.lname}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, lname: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Нэр
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='text'
              placeholder='Нэр'
              value={form.fname}
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, fname: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Хүйс
          </CFormLabel>
          <CCol sm={10}>
            {GENDER.map((obj, i) => {
              return (
                <CFormCheck
                  key={'gender' + i}
                  required
                  inline
                  id={'genderCheck' + i}
                  type='radio'
                  name='gender'
                  value={obj.value}
                  label={obj.label}
                  checked={obj.value == form?.gender}
                  onChange={(e) =>
                    setForm((prev) => {
                      return { ...prev, gender: e.target.value };
                    })
                  }
                />
              );
            })}
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Төрсөн огноо
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              required
              type='date'
              value={form.birthday}
              pattern='\d{4}-\d{2}-\d{2}'
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, birthday: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>
        <CRow className='mb-3'>
          <CFormLabel htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
            Нэмэлт мэдээлэл
          </CFormLabel>
          <CCol sm={10}>
            <CFormTextarea
              type='text'
              value={form.note}
              placeholder='...'
              onChange={(e) =>
                setForm((prev) => {
                  return { ...prev, note: e.target.value };
                })
              }
            />
          </CCol>
        </CRow>

        <CButton color='primary' type='submit'>
          Хадгалах
        </CButton>
      </CForm>
    </div>
  );
}
