import { cilArrowRight, cilClock, cilDelete, cilInfo, cilList, cilPeople, cilSearch, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CButtonGroup, CCardBody, CCol, CFormInput, CInputGroup, CModalFooter, CRow } from '@coreui/react';
import {
  CAvatar,
  CCard,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../../components/modals/Modals';
import { useAuth } from '../../../../context/AuthContext';

import service from '../../../../services/firebase/reservationService';

const includeColumns = ['birthday', 'displayName', 'email', 'fname', 'lname', 'gender', 'phone', 'nextDate'];

function FilteredUsers(props) {
  const navigate = useNavigate();
  const { handleToast, setShowSpinner } = useAuth();

  const [type, setType] = useState('');

  const [query, setQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  const getDates = (type) => {
    let begin = '',
      end = '';
    switch (type) {
      case 'w':
        begin = moment().startOf('W').format('YYYY-MM-DD');
        end = moment().endOf('W').format('YYYY-MM-DD');
        return { begin, end };
      case 'm':
        begin = moment().startOf('month').format('YYYY-MM-DD');
        end = moment().endOf('month').format('YYYY-MM-DD');
        return { begin, end };
      case 'd':
      default:
        begin = moment().format('YYYY-MM-DD');
        end = moment().format('YYYY-MM-DD');
        return { begin, end };
    }
  };
  // console.log('getDates', getDates(), getDates('w'), getDates('m'));

  const handleTimetable = (uid) => {
    console.log('uid', uid);
    if (!uid) return;
    navigate({
      pathname: `/dashboard/reservation/users/${uid}`,
    });
  };
  const handleUserInfo = (uid) => {
    if (!uid) return;
    navigate({
      pathname: `/dashboard/employees/users/${uid}`,
    });
  };
  const fetchData = (type) => {
    setShowSpinner(true);
    setType(type);
    return service
      .getReservationsByNextDate(getDates(type))
      .then((data) => {
        console.log('data', data);
        const users = data.map((obj) => {
          return { ...obj.user, nextDate: obj.nextDate };
        });
        setUsers(users);
      })
      .finally(() => setShowSpinner(false));
  };

  const handleTyping = (e) => {
    const value = e.target.value;
    setQuery(value);
    let query = value.toLowerCase().trim();
    let filtered = [];
    if (query !== '') {
      filtered = users.filter((item) => {
        return Object.keys(item).some((key) =>
          includeColumns.includes(key) ? item[key].toString().toLowerCase().includes(query) : false
        );
      });
    }
    setFilteredUsers(filtered);
  };
  return (
    <>
      <CCard className='mb-4'>
        <CCardHeader>
          <CRow>
            <CCol sm={7}>
              <CButtonGroup role='group' aria-label='Basic outlined example'>
                <CButton onClick={() => fetchData()} color='primary' variant={!type ? '' : 'outline'}>
                  Өнөөдөр
                </CButton>
                <CButton onClick={() => fetchData('w')} color='primary' variant={type === 'w' ? '' : 'outline'}>
                  Энэ 7 хоног
                </CButton>
                <CButton onClick={() => fetchData('m')} color='primary' variant={type === 'm' ? '' : 'outline'}>
                  Энэ сар
                </CButton>
              </CButtonGroup>
            </CCol>
            <CCol sm={5}>
              <CInputGroup>
                <CButton className='float-end' variant='outline'>
                  <CIcon icon={cilSearch} />
                </CButton>
                <CFormInput placeholder='Хайлт' value={query} onChange={handleTyping} />
              </CInputGroup>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>
          <CTable align='middle' className='mb-0 border' hover responsive>
            <CTableHead color='light'>
              <CTableRow>
                <CTableHeaderCell className='text-center'>
                  <CIcon icon={cilList} />
                </CTableHeaderCell>
                <CTableHeaderCell>Цахим шуудан</CTableHeaderCell>
                <CTableHeaderCell>Нэр</CTableHeaderCell> <CTableHeaderCell>Утас</CTableHeaderCell>
                <CTableHeaderCell>Давтан үзлэгийн огноо</CTableHeaderCell>
                <CTableHeaderCell>Үүсгэсэн огноо</CTableHeaderCell>
                <CTableHeaderCell>Үйлдэл</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {(query.length > 0 ? filteredUsers : users).map((item, index) => (
                <CTableRow v-for='item in tableItems' key={index}>
                  <CTableDataCell className='text-center'>
                    {/* {index + 1} */}
                    <CAvatar
                      size='md'
                      src={item.photoURL}
                      // status={item.status === RESERVE_STATUS.reserved ? 'success' : 'error'}
                    />
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{`${item.email}`}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{`${item.fname} ${item.lname}`}</div>
                  </CTableDataCell>{' '}
                  <CTableDataCell>
                    <div className='clearfix'>
                      <div>{item.phone}</div>
                    </div>
                    {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                  </CTableDataCell>{' '}
                  <CTableDataCell>
                    <div>{`${item.nextDate}`}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{moment(item.createdAt).fromNow()}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButtonGroup role='group' aria-label='Basic example'>
                      <CButton
                        color='success'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() => handleUserInfo(item.id)}
                      >
                        <CIcon icon={cilUser} size='xl' />
                      </CButton>
                      <CButton
                        color='info'
                        variant='ghost'
                        shape='rounded-pill'
                        onClick={() => handleTimetable(item.id)}
                      >
                        <CIcon icon={cilArrowRight} size='xl' />
                      </CButton>{' '}
                    </CButtonGroup>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </>
  );
}

export default FilteredUsers;
