import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

// import 'firebase/auth';
import firebaseConfig from './firebaseConfig';

class FirebaseAuthService {
  app;
  auth;
  firestore;
  //   database;
  //   storage;
  function;
  googleProvider;
  facebookProvider;
  twitterProvider;

  constructor() {
    // UNCOMMENT IF YOU WANT TO USE FIREBASE
    this.app = firebase.initializeApp(firebaseConfig);
    this.auth = this.app.auth();

    this.firestore = this.app.firestore();
    this.function = this.app.functions('asia-northeast2');

    // if (window.location.hostname === 'localhost') {
    //   this.firestore.useEmulator('localhost', 8080);
    //   this.function.useEmulator('localhost', 5001);
    //   this.auth.useEmulator('http://localhost:9099');
    // }
    //   this.database  = firebase.database();
    //   this.storage = firebase.storage();
    // this.googleProvider = new firebase.auth.GoogleAuthProvider();
    // this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    // this.twitterProvider = new firebase.auth.TwitterAuthProvider();
  }

  checkAuthStatus = (callback) => {
    this.auth.onAuthStateChanged(callback);
  };

  signUpWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  };

  signInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };

  signInWithPopup = (media) => {
    switch (media) {
      case 'google':
        return this.auth.signInWithPopup(this.googleProvider);

      case 'facebook':
        return this.auth.signInWithPopup(this.facebookProvider);

      case 'twitter':
        return this.auth.signInWithPopup(this.twitterProvider);

      default:
        break;
    }
  };

  signInWithPhoneNumber = (phoneNumber) => {
    return this.auth.signInWithPhoneNumber(phoneNumber);
  };

  getCurrentUserData = async () => {
    await this.auth.currentUser?.reload();
    let user = this.auth.currentUser;

    if (user) {
      //   generally it's better to use uid for docId
      const result = await user.getIdTokenResult();
      const { isAdmin } = result.claims;

      return this.firestore
        .collection('users')
        .doc(user.uid)
        .get()
        .then((doc) => {
          return { ...doc.data(), uid: user.uid, emailVerified: user.emailVerified, isAdmin };
        });
    } else {
      return user;
    }
  };
  getUserData = async (uid = '') => {
    //   generally it's better to use uid for docId

    return this.firestore
      .collection('users')
      .doc(uid)
      .get()
      .then((doc) => {
        return { ...doc.data(), uid: doc.id };
      });
  };
  updateUserData = (docId, fields) => {
    //   generally it's better to use uid for docId
    return this.firestore
      .collection('users')
      .doc(docId)
      .update({ ...fields });
  };
  setEmployeeDoc = (uid, data) => {
    //   generally it's better to use uid for docId
    return this.firestore
      .collection('employee')
      .doc(uid)
      .set({ ...data });
  };
  getEmployees = (uid, data) => {
    //   generally it's better to use uid for docId
    return this.firestore.collection('employee');
  };
  createEmployee = async (params) => {
    const createEmployee = this.function.httpsCallable('userFn-createEmployee');
    return createEmployee(params);
  };
  removeEmployee = async (params) => {
    const removeEmployee = this.function.httpsCallable('userFn-removeEmployee');
    return removeEmployee(params);
  };
  receiveEmail = async (params) => {
    const receiveEmail = this.function.httpsCallable('userFn-receiveEmail');
    return receiveEmail(params);
  };
  getAllUser = () => {
    this.firestore
      .collection('users')
      .get()
      .then((docList) => {
        docList.forEach((doc) => {
          console.log(doc.data());
        });
      });
  };

  getOptions = async (type) => {
    return this.firestore
      .collection('options')
      .where('type', '==', type)
      .get()
      .then((snap) => {
        return snap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
      })
      .catch((err) => {
        console.log('ERR', err);
        return [];
      });
  };
  getLandingPageConstants = async () => {
    return Promise.all([
      this.firestore.doc('constants/greeting').get(),
      this.firestore.doc('constants/aboutUs').get(),
      this.firestore.doc('constants/doctors').get(),
      this.firestore.doc('constants/testimonial').get(),
      this.firestore.doc('constants/googleMap').get(),
    ]).then((docs) => {
      const greeting = docs[0].exists ? docs[0].data() : {};
      const aboutUs = docs[1].exists ? docs[1].data() : {};
      const doctors = docs[2].exists ? docs[2].data() : {};
      const testimonials = docs[3].exists ? docs[3].data() : {};
      const googleMap = docs[4].exists ? docs[4].data() : {};

      return { greeting, aboutUs, doctors, testimonials, googleMap };
    });
  };
  signOut = () => {
    return this.auth.signOut();
  };
}

const instance = new FirebaseAuthService();

export default instance;
export const fb = firebase;
