import React from 'react';
import GoogleMapReact from 'google-map-react';
import CustomMarker from './CustomMarker';
const defaultCoord = { lat: 47.901280, lng: 106.935600 };

// function CustomMap({ lat = defaultCoord.lat, lng = defaultCoord.lng, zoom = 15 }) {
function CustomMap({ coordinates = [], zoom = 13, defaultCenter = { lat: '', lng: '' } }) {
  console.log('apiKey, defaultCenter', defaultCenter, coordinates, zoom);
  return (
    <div>
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBG503emfq8OGej4kimVs9mKGEEbUwecfA' }}
          defaultCenter={{ lat: defaultCoord.lat - 0.014, lng: defaultCoord.lng }}
          defaultZoom={zoom}
        >
          {coordinates?.map(({ lat, lng }, i) => {
            return <CustomMarker key={'marker' + i} lat={lat} lng={lng} text='My Marker' />;
          })}
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default CustomMap;
