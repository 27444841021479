import React from 'react';
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import avatar8 from '../../../assets/images/avatars/8.jpg';
import { useAuth } from '../../../context/AuthContext';

const AppHeaderDropdown = () => {
  const { user, handleLogout } = useAuth();
  return (
    <CDropdown variant='nav-item'>
      <CDropdownToggle placement='bottom-end' className='py-0' caret={false}>
        <CAvatar src={user?.photoURL} size='md' />
      </CDropdownToggle>
      <CDropdownMenu className='pt-0' placement='bottom-end'>
        {/* <CDropdownHeader className='bg-light fw-semibold py-2'>Account</CDropdownHeader>
        <CDropdownItem href='#'>
          <CIcon icon={cilBell} className='me-2' />
          Updates
          <CBadge color='info' className='ms-2'>
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href='#'>
          <CIcon icon={cilEnvelopeOpen} className='me-2' />
          Messages
          <CBadge color='success' className='ms-2'>
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href='#'>
          <CIcon icon={cilTask} className='me-2' />
          Tasks
          <CBadge color='danger' className='ms-2'>
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href='#'>
          <CIcon icon={cilCommentSquare} className='me-2' />
          Comments
          <CBadge color='warning' className='ms-2'>
            42
          </CBadge>
        </CDropdownItem> */}
        <CDropdownHeader className='bg-light fw-semibold py-2'>{user?.displayName}</CDropdownHeader>
        {/* <CDropdownItem href='#'>
          <CIcon icon={cilUser} className='me-2' />
          Профайл
        </CDropdownItem> */}
        {/* <CDropdownItem href='#'>
          <CIcon icon={cilSettings} className='me-2' />
          Settings
        </CDropdownItem> */}
        {/* <CDropdownItem href='#'>
          <CIcon icon={cilCreditCard} className='me-2' />
          Payments
          <CBadge color='secondary' className='ms-2'>
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href='#'>
          <CIcon icon={cilFile} className='me-2' />
          Projects
          <CBadge color='primary' className='ms-2'>
            42
          </CBadge>
        </CDropdownItem> */}
        {/* <CDropdownDivider /> */}
        <CDropdownItem onClick={() => handleLogout()}>
          <CIcon icon={cilLockLocked} className='me-2' />
          Системээс гарах
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
